﻿import { Supplier } from './../../masters/supplier/supplier.model';
import { Payment } from './payment.model';
import { AppComponent } from './../../app.component';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpService } from './../../shared/http-service';
import { APIURLS } from './../../shared/api-url';
import * as _ from "lodash";
declare var jQuery: any;


@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
    public supplierTableWidget: any;
    public productTableWidget: any;
    //public supplierTableWidget: any;
    public payTableWidget: any;
    priceList: any[];
    //errcount: number = 0;
    supplierList: any[];
    divisionList: any[];
    proceedDisable: boolean = false;
    proceedAdvDisable: boolean = true;
    submitDisable: boolean = false;
    isLoading: boolean = false;
    hideAdvTable: boolean = true;
    hideAdvPayTable: boolean = true;
    hidePayTable: boolean = true;
    divisionConstList: any[];
    supplierConstList: any[];
    divisionConst: string;
    supplierConst: string;
    typeTabHeader: string;
    prodTabHeader: string;
    descTabHeader: string;
    hsnTabHeader: string;
    qtyTabHeader: string;
    hsnTitle: string;
    basicValTabHeader: string;
    basicAmtTabHeader: string;
    totValTabHeader: string;
    amtCollTabHeader: string;
    addSupInvHeaderList: any[];
    addSupInvHeaderItem: any[];
    priceSupHeaderList: any[];
    priceSupTabHeaderList: any[];
    cusInvHeaderList: any[];
    paymentList: any[];
    errPaymentList: any[];
    errAdvanceList: any[];
    errAdvAgainstPayList: any[];
    paymentAdvanceList: any[];
    //sdivison: any;
    //scust: any;
    totalAdvanceAmount: number = 0;
    errMsg: string = "";
    errMsgDate: string = "";
    errMsgPAN: string = "";
    errMsgGSTIN: string = "";
    errMsgTabInv: string = "";
    isLoadingPop: boolean = false;
    isValidgstin: boolean = false;
    errMsgPop: string = "";
    errMsgPopInv: string = "";
    errMsgAdvPopInv: string = "";
    modetitle: string = "Add ";
    pay_doc_date: Date = new Date();
    inv_date: Date = new Date();
    invoice: any;
    payment: any;

    ////////////
    supplierItem: Supplier = this.supplierItem = new Supplier(0, 0, '', '', '', '', '', '', '', 0, '', '', '', '', '', '', '', '', '', '', '', '', '', 0, '', '', '', 0, true);;
    constructor(private appService: AppComponent, private httpService: HttpService,
        private route: ActivatedRoute,
        private router: Router, vcr: ViewContainerRef) {
        //this.toastr.setRootViewContainerRef(vcr);
    }

    private initDatatable(): void {
        let exampleId1: any = jQuery('#custTable');
        let exampleId2: any = jQuery('#productTable'); 
        let exampleId3: any = jQuery('#InvPayTable');
        this.supplierTableWidget = exampleId1.DataTable();
        this.payTableWidget = exampleId3.DataTable();
    }

    private reInitDatatable(): void {
        if (this.supplierTableWidget) {
            this.supplierTableWidget.destroy()
            this.supplierTableWidget = null
        }
        if (this.payTableWidget) {
            this.payTableWidget.destroy()
            this.payTableWidget = null
        }
        setTimeout(() => this.initDatatable(), 0)
    }

    ngAfterViewInit() {
        this.initDatatable()
    }

    ngOnInit() {
        this.getSupAddInvHeaders();
        this.getSupDivisionConst();
        this.getSupplierConst();
        //this.route.params.subscribe(params => { this.invid = params['id']; });
        //if (this.invid > 0) {
        //    this.modetitle = "Update ";
        //    this.invoice = { inv_division: {}, inv_exp_dom: 'Domestic', inv_ord_nbr: '', inv_date: new Date(), inv_customer: {}, inv_supply_type: -1, inv_pt_type: 0, inv_total_value: 0.0, inv_total_collected: 0.0, inv_total_sgst: 0.0, inv_total_cgst: 0.0, inv_total_igst: 0.0, inv_total_cess: 0.0, inv_port_code: '', inv_remarks: '', inv_contact_name:'', inv_gst_pay: 1, invoiceDetails: [] };
        //    this.getInvoiceById();
        //}
        //else {
        //    this.invoice = { inv_division: {}, inv_exp_dom: 'Domestic', inv_ord_nbr: '', inv_date: new Date(), inv_customer: {}, inv_supply_type: -1, inv_pt_type: 0, inv_total_value: 0.0, inv_total_collected: 0.0, inv_total_sgst: 0.0, inv_total_cgst: 0.0, inv_total_igst: 0.0, inv_total_cess: 0.0, inv_port_code: '', inv_remarks: '', inv_contact_name:'', inv_gst_pay: 1, invoiceDetails: [] };
        //}
        this.payment = { pay_division: {},pay_supplier: {}, pay_actual_paid: 0.0, pay_advance: '', pay_doc_date: new Date(), paymentDetails: [], paymentAdvDetails: []  };

        this.getDivision();
    }

    openSupplierSearch() {
        jQuery("#myModalSupplier").modal('show');
        this.getSupplierList();
    }

    //openCustSearch() {
    //    jQuery("#myModalCust").modal('show');
    //    this.getInvCustHeaders();
    //    this.getCustomerList();
    //}
    validatePaidAmt(payment) {
        //debugger;
        this.errMsgPopInv = '';
        this.errMsgAdvPopInv = '';
        this.errMsgAdvPopInv = '';
        this.errMsgTabInv = '';

        if (payment.pay_total_amt_paid > payment.pay_out_balance && payment.pay_po_nbr != 'Advance') {
            this.errMsgTabInv = "Amount paid is greater than the Outstanding amount for invoice : " + payment.pay_po_nbr;
            //this.
            //this.errPaymentList.push(payment);
           // this.payment.pay_total_amt_paid.focus();
        }
        else {
            this.errMsgTabInv = '';
        }
        //if (this.totalAdvanceAmount>0){
        //    if (this.totalAdvanceAmount - payment.pay_total_amt_paid >= 0) {
        //        this.totalAdvanceAmount = this.totalAdvanceAmount - payment.pay_total_amt_paid;
        //    }
        //    else if (this.totalAdvanceAmount - payment.pay_total_amt_paid < 0) {
        //        this.errMsgPopInv = "Amount paid is greater than the Advance Amount for invoice : " + payment.pay_po_nbr;
        //    }
        //    else {
        //        this.errMsgPopInv = '';
        //    }
        //}
    }
    onPaymentFilterChange(payMode, lnPayment) {
        lnPayment.pay_payment_mode = payMode;
    }

    selectAdvanceType(selectAdvanceType: any) {
        this.payment.pay_advance = selectAdvanceType;
    }


    validateForm() {
        this.errMsg = "";
        if (this.payment.pay_division.divid == undefined || this.payment.pay_division.divid == undefined || this.payment.pay_division.divid == 0) {
            this.errMsg = "please select division";
            return false;
        }
        
        //if (this.payment.inv_gst_pay == undefined || this.payment.inv_gst_pay <= 0) {
        //    this.errMsg = "please select GST Payment type.";
        //    return false;
        //}

        if (this.payment.pay_supplier == undefined || this.payment.pay_supplier.vend_state_code == undefined || this.payment.pay_supplier.vend_state_code.length == 0) {
            this.errMsg = "please select supplier or selected supplier not associated with state.";
            return false;
        }

        //if (this.payment.inv_supplier.cust_name == "General Customer" && this.payment.inv_contact_name=="") {
        //    this.errMsg = "please enter supplier name.";
        //    return false;
        //}
        //debugger;

        if (this.pay_doc_date == undefined || this.pay_doc_date == null || this.pay_doc_date.toString() == "") {

            this.errMsgDate = "please select payment date.";
            return false;
        }

        if (this.errMsgDate == 'Enter valid payment Date') {
            this.errMsgDate = "Enter valid payment Date.";
            return false;
        }


        if (this.payment.pay_advance == undefined || this.payment.pay_advance == '') {

            this.errMsg = "please select transaction type.";
            return false;
        }

        //if (this.payment.inv_ref_no == undefined || this.payment.inv_ref_no.length == 0) {
        //    this.errMsg = "please select payment reference no.";
        //    return false;
        //}

        //if (this.payment.pay_actual_collected == undefined || this.payment.pay_actual_collected == 0) {
        //    this.errMsg = "please enter payment amount.";
        //    return false;
        //}


        //var notValidQyt = _.filter(this.invoice.invoiceDetails, function (obj: any) { return parseInt(obj.invd_qty) == NaN || obj.invd_qty < 0; });
        //var notvalidePrice = _.filter(this.invoice.invoiceDetails, function (obj: any) { return parseInt(obj.invd_price) == NaN || obj.invd_price < 0; });
        //if (notValidQyt || this.invoice.inv_total_cess<0 || notvalidePrice) {
        //    this.errMsg = "please feed proper cess,price and quatity value.";
        //    return false;
        //}
        return true;
    }

    getPaymentTable() {
        this.hideAdvPayTable = true;
        this.hidePayTable = false;
        this.hideAdvTable = true;
        //this.proceedAdvDisable = true;
        this.reInitDatatable();
    }
    getAdvanceFullPaymentTable() {
        this.hideAdvPayTable = false;
        this.hidePayTable = true;
        this.hideAdvTable = false;
        //this.proceedAdvDisable = true;
        this.reInitDatatable();
    }
    getAdvPaymentTable() {
        this.hideAdvTable = false;
        this.hideAdvPayTable = true;
        this.hidePayTable = true;
        this.proceedAdvDisable = false;
        this.reInitDatatable();
    }
    //setCheckBox(event) {
    //    if (event) {
    //        this.payment.pay_advance = true;
    //    }
    //    else {
    //        this.payment.pay_advance = false;
    //    }
    //}

    cancelPayment() {
        // debugger;
        //this.collection.col_division = [];

        this.errMsg = "";
        this.errMsgAdvPopInv = '';
        this.errMsgPopInv = "";
        this.hideAdvTable = true;
        this.hideAdvPayTable = true;
        this.hidePayTable = true;
        this.payment.pay_division = [];
        this.divisionList = [];
        this.ngOnInit();
        this.submitDisable = false;
        this.proceedDisable = false;
        this.proceedAdvDisable = false;
        //this.errMsg = "";
        //this.errMsgPopInv = "";
        //this.hideColTable = true;
        //this.router.navigateByUrl("/collection");
    }


    onProceedClick() {
        this.errMsg = "";
        this.errMsgAdvPopInv = '';
        this.errMsgPopInv = "";
        if (!this.validateForm()) {
            return;
        }
        
        else {
           // debugger;
            this.payment.pay_doc_date = Number(new Date(this.pay_doc_date));
            this.payment.paymentDetails = [];
            if (this.payment.pay_advance == "Advance Payment") {
                // this.hidePayTable = true;
                var pod = { pay_balance: 0, pay_doc_date: 0, pay_doc_nbr: '', pay_ord_nbr: '', pay_out_balance: 0, pay_po_date: Number(new Date()), pay_po_nbr: 'Advance', pay_poid: 0, pay_remarks: '', pay_payment_mode: '', pay_total_amt: 0, pay_total_amt_paid: 0.0, pay_total_paid: 0, pay_total_tax: 0.0, pay_total_value: 0, pay_type: '', payid: 0 };
                // if (isChecked && findex == -1) {
                this.payment.paymentDetails.push(pod);
                //if (this.payment.paymentDetails.length == 1) {
                this.getPaymentTable();
                //this.hidePayTable = true;
                this.hideAdvPayTable = true;
                this.hideAdvTable = true;
                //this.proceedDisable = true;
                //}
                // }
            }
            else if (this.payment.pay_advance == "Payment Against Invoices") {
                let connection: any;
                connection = this.httpService.put(APIURLS.BR_MASTER_PAYMENT_API, this.payment.pay_division.divid, this.payment);
                connection.then((data: any) => {
                    //this.isLoadingPop = false;
                    //debugger;
                    if (data.status == 'SUCCESS') {
                        this.payment.paymentDetails = data.ddPaymentList;
                        // this.errPaymentList = data.ddPaymentList;
                        if (this.payment.paymentDetails.length > 0) {
                            this.getPaymentTable();
                        }
                        else {
                            this.errMsgPopInv = "No Pending Invoice Records Exist.";
                            this.hidePayTable = true;
                            this.hideAdvPayTable = true;
                            this.hideAdvTable = true;
                        }
                        //this.paymentList = data.payment.
                        //debugger;
                        //this.toastr.success(data.message+"", 'Success!');
                        // this.router.navigateByUrl('/invoices');
                    }
                    else {
                        this.hidePayTable = true;
                        this.hideAdvPayTable = true;
                        this.hideAdvTable = true;
                        this.isLoadingPop = false;
                        this.proceedDisable = false;
                        this.errMsgPopInv = data.message;
                    }
                }).catch(error => {
                    this.isLoadingPop = false;
                    this.errMsgPopInv = 'Error fetching payment data..';
                });
            }
            else if (this.payment.pay_advance == "Adjust Invoice Against Advance"){
                //this.payment.pay_doc_date = Number(new Date(this.payment.pay_doc_date));
                //this.hideAdvTable = false;
                let connection: any;
                connection = this.httpService.put(APIURLS.BR_PAYMENT_ADV_API, this.payment.pay_division.divid, this.payment);
                connection.then((data: any) => {
                    //this.isLoadingPop = false;
                    //debugger;
                    if (data.status == 'SUCCESS') {
                        this.paymentAdvanceList = data.ddAdvPaymentList;
                        // this.errPaymentList = data.ddPaymentList;
                        if (this.paymentAdvanceList.length > 0) {
                            this.getAdvPaymentTable();
                        }
                        else {
                            this.errMsgPopInv = "No Advance Payment Records Exist.";
                            this.hidePayTable = true;
                            this.hideAdvPayTable = true;
                            this.hideAdvTable = true;
                        }
                        //this.paymentList = data.payment.
                        //debugger;
                        //this.toastr.success(data.message+"", 'Success!');
                        // this.router.navigateByUrl('/invoices');
                    }
                    else {
                        this.hidePayTable = true;
                        this.hideAdvPayTable = true;
                        this.hideAdvTable = true;
                        this.isLoadingPop = false;
                        this.proceedDisable = false;
                        this.errMsgPopInv = data.message;
                    }
                }).catch(error => {
                    this.isLoadingPop = false;
                    this.errMsgPopInv = 'Error fetching advance payment data..';
                });
            }
        }
    }

    onAdvProceedClick() {
        this.errMsg = "";
        this.errMsgAdvPopInv = '';
        this.errMsgPopInv = "";
        this.errMsgTabInv = "";
        if (this.payment.paymentAdvDetails == undefined || this.payment.paymentAdvDetails.length <= 0) {
            this.errMsgAdvPopInv = 'Please Select a Record';
        }
        else {
            let connection: any;
            connection = this.httpService.put(APIURLS.BR_MASTER_PAYMENT_API, this.payment.pay_division.divid, this.payment);
            connection.then((data: any) => {
                //this.isLoadingPop = false;
                //debugger;
                if (data.status == 'SUCCESS') {
                    this.payment.paymentDetails = data.ddPaymentList;
                    
                    // this.errPaymentList = data.ddPaymentList;
                    if (this.payment.paymentDetails.length > 0) {
                        this.getAdvanceFullPaymentTable();
                        //this.hidePayTable = true;
                       // this.hideAdvPayTable = false;
                        //this.hideAdvPayTable = true;
                       // this.hideAdvTable = false;
                    }
                    else {
                        this.errMsgPopInv = "No Pending Invoice Records Exist.";
                        this.hidePayTable = true;
                        this.hideAdvPayTable = true;
                       // this.hideAdvPayTable = true;
                        this.hideAdvTable = true;
                    }
                    //this.paymentList = data.payment.
                    //debugger;
                    //this.toastr.success(data.message+"", 'Success!');
                    // this.router.navigateByUrl('/invoices');
                }
                else {
                    this.hidePayTable = true;
                    this.hideAdvPayTable = true;
                    this.hideAdvTable = true;
                    this.isLoadingPop = false;
                    this.proceedDisable = false;
                    this.errMsgPopInv = data.message;
                }
            }).catch(error => {
                this.isLoadingPop = false;
                this.errMsgPopInv = 'Error fetching payment data..';
            });
        }
    }

    saveAdvPayment() {
        this.errMsg = "";
        this.errMsgAdvPopInv = '';
        this.errMsgPopInv = "";
        this.errMsgTabInv = "";


        //debugger;
        this.errPaymentList = _.filter(this.payment.paymentDetails, function (o: any) {
            if (o.pay_total_amt_paid > o.pay_out_balance && o.pay_po_nbr != 'Advance')
                return o;
        });
        //this.errAdvanceList = _.filter(this.payment.paymentDetails, function (o: any) {
        //    if (o.pay_total_amt_paid == 0 && o.pay_po_nbr == 'Advance')
        //        return o;
        //});
        if (this.errPaymentList.length > 0) {
           // debugger;
            this.errMsgTabInv = 'Outstanding Amount is greater than Amount Paid';
        }
        //else if (this.payment.pay_advance && this.errAdvanceList.length > 0) {
        //    this.errMsgTabInv = 'Please Enter Amount Paid for Advance';
        //}
        else {
            // debugger;
            this.errMsgTabInv = '';
            // }
            if (!this.validateForm()) {
                return;
            }
            if (this.payment.paymentAdvDetails.length > 0 && this.payment.pay_advance == "Adjust Invoice Against Advance") {
                this.validateAdvanceAgainstAmount();
                if (this.errAdvAgainstPayList.length > 0) {
                    this.errMsgTabInv = 'Amount paid is greater than the Advance Amount for invoice';
                    return;
                }
            }
            //this.isLoadingPop = true;
            this.payment.pay_doc_date = Number(new Date(this.pay_doc_date));
            let connection: any;
            connection = this.httpService.post(APIURLS.BR_MASTER_PAYMENT_API, this.payment);
            connection.then((data: any) => {
                // this.isLoadingPop = false;
                this.submitDisable = true;
                this.proceedAdvDisable = true;
                this.proceedDisable = true;
                //this.hideAdvTable = true;
                this.hidePayTable = true;
                this.hideAdvPayTable = false;
                this.proceedAdvDisable = true;
                if (data.status == 'SUCCESS') {
                    this.payment = data.payment;
                    this.paymentAdvanceList = data.payment.paymentAdvDetails;
                    this.payment.paymentAdvDetails = [];
                    if (this.payment.paymentDetails.length > 0) {
                       // debugger;
                        this.getAdvanceFullPaymentTable();
                        this.getAdvPaymentTable();
                        this.hidePayTable = true;
                        this.hideAdvPayTable = false;
                        this.hideAdvTable = false;
                        this.proceedAdvDisable = true;
                    }
                    else {
                        this.errMsgPopInv = "No Pending Invoice Records Exist.";
                        this.hidePayTable = true;
                        this.hideAdvPayTable = true;
                        this.hideAdvTable = true;
                    }
                    //this.paymentList = data.payment.
                    //debugger;
                    //this.toastr.success(data.message+"", 'Success!');
                    // this.router.navigateByUrl('/invoices');
                }
                else {
                    this.hidePayTable = true;
                    this.hideAdvPayTable = true;
                    this.hideAdvTable = true;
                    this.isLoadingPop = false;
                    this.proceedDisable = false;
                    this.errMsgPopInv = data.message;
                }
            }).catch(error => {
                this.isLoadingPop = false;
                this.errMsgPopInv = 'Error saving payment data..';
            });
        }
    }

    savePayment() {
        this.errMsg = "";
        this.errMsgAdvPopInv = '';
        this.errMsgPopInv = "";
        this.errMsgTabInv = "";

        
        //debugger;
        this.errPaymentList = _.filter(this.payment.paymentDetails, function (o: any) {
            if (o.pay_total_amt_paid > o.pay_out_balance && o.pay_po_nbr!='Advance')
            return o;
        });
        this.errAdvanceList = _.filter(this.payment.paymentDetails, function (o: any) {
            if (o.pay_total_amt_paid == 0 && o.pay_po_nbr == 'Advance')
                return o;
        });
        if (this.errPaymentList.length > 0) {
            //debugger;
            this.errMsgTabInv = 'Outstanding Amount is greater than Amount Paid';
        }
        else if (this.payment.pay_advance && this.errAdvanceList.length>0) {
                this.errMsgTabInv = 'Please Enter Amount Paid for Advance';
        }
        else {
           // debugger;
            this.errMsgTabInv = '';
            // }
            if (!this.validateForm()) {
                return;
            }
            //if (this.payment.paymentAdvDetails.length > 0 && this.payment.pay_advance =="Adjust Invoice Against Advance") {
            //    this.validateAdvanceAgainstAmount();
            //    if (this.errAdvAgainstPayList.length > 0) {
            //        this.errMsgTabInv = 'Amount paid is greater than the Advance Amount for invoice';
            //        return;
            //    }
            //}
            //this.isLoadingPop = true;
            this.payment.pay_doc_date = Number(new Date(this.pay_doc_date));
            let connection: any;
            connection = this.httpService.post(APIURLS.BR_MASTER_PAYMENT_API, this.payment);
            connection.then((data: any) => {
               // this.isLoadingPop = false;
                this.submitDisable = true;
                this.proceedAdvDisable = true;
                this.proceedDisable = true;
                //this.hideAdvTable = true;
                this.hidePayTable = false;
                this.hideAdvPayTable = true;
                this.proceedAdvDisable = true;
                if (data.status == 'SUCCESS') {
                    this.payment = data.payment;
                    this.paymentAdvanceList = data.payment.paymentAdvDetails;
                    this.payment.paymentAdvDetails = [];
                    if (this.payment.paymentDetails.length > 0) {
                       // debugger;
                        this.getPaymentTable();
                        //this.getAdvPaymentTable();
                        this.hidePayTable = false;
                        this.hideAdvPayTable = true;
                        this.hideAdvTable = true;
                        this.proceedAdvDisable = true;
                    }
                    else {
                        this.errMsgPopInv = "No Pending Invoice Records Exist.";
                        this.hidePayTable = true;
                        this.hideAdvPayTable = true;
                        this.hideAdvTable = true;
                    }
                    //this.paymentList = data.payment.
                    //debugger;
                    //this.toastr.success(data.message+"", 'Success!');
                   // this.router.navigateByUrl('/invoices');
                }
                else {
                    this.hidePayTable = true;
                    this.hideAdvPayTable = true;
                    this.hideAdvTable = true;
                    this.isLoadingPop = false;
                    this.proceedDisable = false;
                    this.errMsgPopInv = data.message;
                }
            }).catch(error => {
                this.isLoadingPop = false;
                this.errMsgPopInv = 'Error saving payment data..';
            });
        }
    }

    validateAdvanceAgainstAmount() {
        this.errMsgTabInv = '';
        var advAmt = this.totalAdvanceAmount;

        this.errAdvAgainstPayList = _.filter(this.payment.paymentDetails, function (o: any) {
            if (advAmt - o.pay_total_amt_paid >= 0) {
                //debugger;
                advAmt = advAmt - o.pay_total_amt_paid;
                //return o;
            }
            else if (advAmt - o.pay_total_amt_paid < 0) {
                return o;
            }
        });
       // debugger;
        this.totalAdvanceAmount = advAmt;

    }

    //saveInv() {
    //    this.errMsg = "";
    //    this.errMsgPopInv = "";
    //    if (!this.validateForm()) {
    //        return;
    //    }
    //    this.isLoadingPop = true;
    //    this.invoice.inv_date = Number(new Date(this.invoice.inv_date));
    //    let connection: any;
    //    connection = this.httpService.post(APIURLS.BR_INVOICE_API, this.invoice);
    //    connection.then((data: any) => {
    //        this.isLoadingPop = false;
    //        if (data.status == 'SUCCESS') {
    //            //this.toastr.success(data.message+"", 'Success!');
    //            this.router.navigateByUrl('/invoices');
    //        }
    //        else {
    //            this.isLoadingPop = false;
    //            this.errMsgPopInv = data.message;
    //        }
    //    }).catch(error => {
    //        this.isLoadingPop = false;
    //        this.errMsgPopInv = 'Error saving invoice data..';
    //    });
    //}

    onSupplierSelectionChange(supplier) {
        this.payment.pay_supplier = supplier;
    }

    selectAdvPayment(isChecked: boolean, advPay: any) {
        this.errMsgAdvPopInv = '';
        //debugger;
        var findex = this.payment.paymentAdvDetails.findIndex(item => item.payid == advPay.payid);
        var payAd = { payid: advPay.payid, pay_type: advPay.pay_type, pay_remarks: advPay.pay_remarks, pay_payment_mode: advPay.pay_payment_mode, pay_ord_nbr: advPay.pay_ord_nbr, pay_doc_nbr: advPay.pay_doc_nbr, pay_doc_date: advPay.pay_doc_date, pay_balance: advPay.pay_balance, pay_amount: advPay.pay_amount, }
       // debugger;
        //var invd = { invd_category: product.pt_type == 0 ? 'Services' : 'Goods', invd_item: product.pt_name, invd_item_descp: product.pt_desc, pid: product.plid, invd_hsn_sac: product.pt_hsn_code, invd_qty: 1, invd_price: product.pt_price, invd_uom: '', invd_value: 1 * product.pt_price, invd_sgst_rate: product.pt_sgst, invd_sgst_amt: 0.0, invd_cgst_rate: product.pt_cgst, invd_cgst_amt: 0.0, invd_igst_rate: product.pt_igst, invd_igst_amt: 0.0, invd_cess_rate: product.pt_cess, invd_cess_amt: 0.0, invd_collected_amt: 0.0 };
        if (isChecked && findex == -1) {
            this.totalAdvanceAmount = this.totalAdvanceAmount + advPay.pay_balance;
            this.payment.paymentAdvDetails.push(payAd);
        }
        else {
            if (isChecked && findex > -1) {
                // this.invoice.invoiceDetails.splice(findex, 1);
            }
            else if (!isChecked && findex > -1) {
                this.totalAdvanceAmount = this.totalAdvanceAmount - advPay.pay_balance;
                this.payment.paymentAdvDetails.splice(findex, 1);
            }
            else if (findex > -1) {
                this.totalAdvanceAmount = this.totalAdvanceAmount - advPay.pay_balance;
                this.payment.paymentAdvDetails.splice(findex, 1);
            }
        }
    }

    //onCustSelectionChange(customer) {
    //    this.payment.pay_customer = customer;
    //}
    //getAddInvHeaders() {

    //    this.httpService.get(APIURLS.BR_APP_INV_STRUCTURE_API).then((data: any) => {
    //        if (data.status == 'SUCCESS') {

    //            this.addInvHeaderList = data.appInvStrList;
    //            this.addInvHeaderList = data.appInvStrList.find(s => s.subMenuId == '40'); //_.filter(data.formData, function (obj) { if (obj.name == 'Entity') return obj; });
    //            this.addInvHeaderItem = _.filter(data.appInvStrList, function (obj) { if (obj.subMenuId == '40') return obj; });
    //            this.typeTabHeader = _.map(this.addInvHeaderItem, 'add_view_type');
    //            this.prodTabHeader = _.map(this.addInvHeaderItem, 'product');
    //            this.descTabHeader = _.map(this.addInvHeaderItem, 'description');
    //            this.hsnTabHeader = _.map(this.addInvHeaderItem, 'hsN_SAC');
    //            this.qtyTabHeader = _.map(this.addInvHeaderItem, 'quantity');
    //            this.basicValTabHeader = _.map(this.addInvHeaderItem, 'basicvalue_price');
    //            this.basicAmtTabHeader = _.map(this.addInvHeaderItem, 'basic_amount');
    //            this.totValTabHeader = _.map(this.addInvHeaderItem, 'total_value');
    //            this.amtCollTabHeader = _.map(this.addInvHeaderItem, 'amt_collected_and_Paid');

    //        }
    //    }).catch(error => {
    //        this.addInvHeaderList = null;
    //    });
    //}

    getSupplierList() {
        this.isLoading = true;
        this.httpService.get(APIURLS.BR_MASTER_SUPPLIER_API).then((data: any) => {
            this.isLoading = false;
            if (data.status == 'SUCCESS') {
                //this.supplierList = data.supplierList;
                this.supplierList = _.filter(data.supplierList, function (o: any) { return o.status == 1; });
            }

            this.reInitDatatable();
        }).catch(error => {
            this.isLoading = false;
            this.supplierList = [];
        });
    }

    
    getDivision() {
       // this.isLoading = true;
        this.httpService.get(APIURLS.BR_MASTER_FILTERDIV_API).then((data: any) => {
           // this.isLoading = false;
            if (data.status == 'SUCCESS')
                this.divisionList = data.divisionList;
            if (this.divisionList.length == 1) {
                this.payment.pay_division = this.divisionList[0];
            }
            this.reInitDatatable();
        }).catch(error => {
          //  this.isLoading = false;
            this.divisionList = [];
        });
    }
    setPaymentDate(payDate: any) {
       // this.errMsg = "";
       // debugger;
        //if (payDate == undefined || payDate == null || payDate.toString() == "") {
        //    this.errMsg = "please select payment date.";
        //} else {
        //    this.errMsg = "";
        //    this.pay_doc_date = Number(new Date(payDate));
        //}
        this.errMsgDate = '';
        var date_regex = /^((((19|[2-9]\d)\d{2})\-(0[13578]|1[02])\-(0[1-9]|[12]\d|3[01]))|(((19|[2-9]\d)\d{2})\-(0[13456789]|1[012])\-(0[1-9]|[12]\d|30))|(((19|[2-9]\d)\d{2})\-02\-(0[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))\-02\-29))$/g;
        if ((date_regex.test(payDate))) {
            this.pay_doc_date = payDate;
        }
        else if (payDate == "") {
            this.errMsgDate = 'Enter valid payment Date';
        }

    }
    getSupAddInvHeaders() {

        this.httpService.get(APIURLS.BR_APP_INV_STRUCTURE_API).then((data: any) => {
            if (data.status == 'SUCCESS') {

                this.addSupInvHeaderList = data.appInvStrList;
                this.addSupInvHeaderList = data.appInvStrList.find(s => s.subMenuId == '40'); //_.filter(data.formData, function (obj) { if (obj.name == 'Entity') return obj; });
                this.addSupInvHeaderItem = _.filter(data.appInvStrList, function (obj) { if (obj.subMenuId == '40') return obj; });

                this.typeTabHeader = _.map(this.addSupInvHeaderItem, 'add_view_type');
                this.prodTabHeader = _.map(this.addSupInvHeaderItem, 'product');
                this.descTabHeader = _.map(this.addSupInvHeaderItem, 'description');
                this.hsnTabHeader = _.map(this.addSupInvHeaderItem, 'hsN_SAC');
                this.qtyTabHeader = _.map(this.addSupInvHeaderItem, 'quantity');
                this.basicValTabHeader = _.map(this.addSupInvHeaderItem, 'basicvalue_price');
                this.basicAmtTabHeader = _.map(this.addSupInvHeaderItem, 'basic_amount');
                this.totValTabHeader = _.map(this.addSupInvHeaderItem, 'total_value');
                this.amtCollTabHeader = _.map(this.addSupInvHeaderItem, 'amt_collected_and_Paid');

            }
        }).catch(error => {
            this.addSupInvHeaderList = null;
        });
    }
    getSupDivisionConst() {
        this.httpService.get(APIURLS.BR_MASTER_PROFILEFORMMAIN_API).then((data: any) => {
            if (data.status == 'SUCCESS') {
                //this.urlList = data.formMaintenanceList;
                this.divisionConstList = _.filter(data.formMaintenanceList, function (obj) { if (obj.menuId == "2" && obj.subMenuId == '3') return obj; });
                this.divisionConst = _.map(this.divisionConstList, 'name');
            }
        }).catch(error => {
            this.divisionConstList = [];
        });
    }
    getSupplierConst() {
        this.httpService.get(APIURLS.BR_MASTER_PROFILEFORMMAIN_API).then((data: any) => {
            if (data.status == 'SUCCESS') {
                //this.urlList = data.formMaintenanceList;
                this.supplierConstList = _.filter(data.formMaintenanceList, function (obj) { if (obj.menuId == "2" && obj.subMenuId == '4') return obj; });
                this.supplierConst = _.map(this.supplierConstList, 'name');
            }
        }).catch(error => {
            this.supplierConstList = [];
        });
    }
}
