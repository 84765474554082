import { AuthData } from './../auth/auth.model';
import { APIURLS } from './api-url';
import { Http, RequestOptions, Headers } from '@angular/http';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';
@Injectable()
export class HttpService {

    constructor(private http: Http) {

    }
    getHeaderForLogin(): any {
        var headers = new Headers();
        headers.append("Accept", 'application/json; charset=utf-8');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        let options = new RequestOptions({ headers: headers });
        return options;
    }
    getHeaderForForgotPwd(): any {
        var headers = new Headers();
        headers.append("Accept", 'application/json; charset=utf-8');
        headers.append('Content-Type', 'application/json');
        let options = new RequestOptions({ headers: headers });
        return options;
    }

    getHeader(): any {
        var headers = new Headers();
        headers.append("Accept", 'application/json');
        headers.append('Content-Type', 'application/json');
        let authData: AuthData = JSON.parse(localStorage.getItem('currentUser'))
        headers.append("Authorization", "Bearer " + authData.token);
        let options = new RequestOptions({ headers: headers });
        return options;
    }

    getHeaderForFileUpload(): any {
      var headers = new Headers();
      headers.append("Accept", 'application/json; charset=utf-8');
      //headers.append('Content-Type', 'multipart/form-data; charset=utf-8');
      //headers.append('Access-Control-Allow-Origin', '*');
      let authData: AuthData = JSON.parse(localStorage.getItem('currentUser'))
      headers.append("Authorization", "Bearer " + authData.token);
      let options = new RequestOptions({ headers: headers });
      return options;
    }

    postLogin(postParams): any {
        var data = "grant_type=password&username=" + postParams.username + "&password=" + postParams.password + "&useragent=BOOKRIGHT_WEB";
        const promise = new Promise((resolve, reject) => {
            this.http.post(APIURLS.BR_BASE_URL + APIURLS.BR_AUTH_API, data, this.getHeaderForLogin())
                .toPromise()
                .then(
                res => { // Success
                    console.log(res.json());
                    resolve(res.json());
                },
                err => {
                    console.log(err.json());
                    reject(err.json());
                }
                );

        });
        return promise;
    }

    forgotpassword(apiKey: string, id: string, postParams): any {
        const promise = new Promise((resolve, reject) => {
            //this.http.post(APIURLS.BR_BASE_URL + apiKey + '/' + mailid + '/', this.getHeaderForForgotPwd())
            this.http.put(APIURLS.BR_BASE_URL + apiKey + '/' + id + '/', postParams, this.getHeaderForForgotPwd())
                .toPromise()
                .then(
                res => { // Success
                    console.log(res.json());
                    resolve(res.json());
                },
                err => {
                    console.log(err.json());
                    reject(err.json());
                }
                );

        });
        return promise;
    }

    get(apiKey: string): any {
        const promise = new Promise((resolve, reject) => {
            this.http.get(APIURLS.BR_BASE_URL + apiKey, this.getHeader())
                .toPromise()
                .then(
                res => { // Success
                    console.log(res.json());
                    resolve(res.json());
                },
                err => {
                    console.log(err.json());
                    reject(err.json());
                }
                );

        });
        return promise;
    }

    getEntityList(apiKey: string): any {
        const promise = new Promise((resolve, reject) => {
            this.http.get(APIURLS.BR_BASE_URL + apiKey, this.getHeader())
                .toPromise()
                .then(
                res => { // Success
                    console.log(res.json());
                    resolve(res.json());
                },
                err => {
                    console.log(err.json());
                    reject(err.json());
                }
                );

        });
        return promise;
    }

    getById(apiKey: string, id: number): any {
        const promise = new Promise((resolve, reject) => {
            this.http.get(APIURLS.BR_BASE_URL + apiKey + "/" + id, this.getHeader())
                .toPromise()
                .then(
                res => { // Success
                    console.log(res.json());
                    resolve(res.json());
                },
                err => {
                    console.log(err.json());
                    reject(err.json());
                }
                );

        });
        return promise;
    }

    post(apiKey: string, postParams): any {
        const promise = new Promise((resolve, reject) => {
            this.http.post(APIURLS.BR_BASE_URL + apiKey, postParams, this.getHeader())
                .toPromise()
                .then(
                res => { // Success
                    console.log(res.json());
                    resolve(res.json());
                },
                err => {
                    console.log(err.json());
                    reject(err.json());
                }
                );

        });
        return promise;
    }

    put(apiKey: string, id: number, postParams): any {
        const promise = new Promise((resolve, reject) => {
            this.http.put(APIURLS.BR_BASE_URL + apiKey + "/" + id, postParams, this.getHeader())
                .toPromise()
                .then(
                res => { // Success
                    console.log(res.json());
                    resolve(res.json());
                },
                err => {
                    console.log(err.json());
                    reject(err.json());
                }
                );

        });
        return promise;
    }

    patch(apiKey: string, id: string, postParams): any {
      const promise = new Promise((resolve, reject) => {
        //this.http.patch(APIURLS.BR_BASE_URL + apiKey + "/" + id, postParams, this.getHeaderForFileUpload())
        this.http.patch(APIURLS.BR_BASE_URL + apiKey + "/" + id, postParams, this.getHeader())
          .toPromise()
          .then(
            res => { // Success
              console.log(res.json());
              resolve(res.json());
            },
            err => {
              console.log(err.json());
              reject(err.json());
            }
          );

      });
      return promise;
    }

    delete(apiKey: string, id: number): any {
        const promise = new Promise((resolve, reject) => {
            this.http.delete(APIURLS.BR_BASE_URL + apiKey + "/" + id, this.getHeader())
                .toPromise()
                .then(
                res => { // Success
                    console.log(res.json());
                    resolve(res.json());
                },
                err => {
                    console.log(err.json());
                    reject(err.json());
                }
                );

        });
        return promise;
    }

    postforUploadFiles(apiKey: string, id: number, postParams): any {
      const promise = new Promise((resolve, reject) => {
          this.http.put(APIURLS.BR_BASE_URL + apiKey + "/"+ id, postParams, this.getHeaderForFileUpload())
          .toPromise()
          .then(
          res => { // Success
            console.log(res.json());
            resolve(res.json());
          },
          err => {
            console.log(err.json());
            reject(err.json());
          }
          );

      });
      return promise;
    }
}
