import { DivisionComponent } from './masters/division/division.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth-guard.service';
import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { CompanyComponent } from './company/company.component';
import { EntityComponent } from './masters/entity/entity.component';
import { InvoicelistComponent } from './gstr1/invoice/invoicelist/invoicelist.component';
import { AddInvoiceComponent } from './gstr1/invoice/add-invoice/add-invoice.component';
import { InvoiceViewComponent } from './gstr1/invoice/invoice-view/invoice-view.component';
import { GInvoicelistComponent } from './gstr1/ginvoice/ginvoicelist/ginvoicelist.component';
import { AddGInvoiceComponent } from './gstr1/ginvoice/add-ginvoice/add-ginvoice.component';
import { GInvoiceViewComponent } from './gstr1/ginvoice/ginvoice-view/ginvoice-view.component';
import { InvoiceApprovalListComponent } from './gstr1/invoice-approval/inv-approval-list/inv-approval-list.component';
import { SupInvoicelistComponent } from './gstr2/supinvoice/supinvoicelist/supinvoicelist.component';
import { SupAddInvoiceComponent } from './gstr2/supinvoice/supadd-invoice/supadd-invoice.component';
import { SupInvoiceViewComponent } from './gstr2/supinvoice/supinvoice-view/supinvoice-view.component';
import { SupRinvoicelistComponent } from './gstr2/suprinvoice/suprinvoicelist/suprinvoicelist.component';
import { SupAddRinvoiceComponent } from './gstr2/suprinvoice/supadd-rinvoice/supadd-rinvoice.component';
import { SupRinvoiceViewComponent } from './gstr2/suprinvoice/suprinvoice-view/suprinvoice-view.component';
import { SupInvoiceApprovalListComponent } from './gstr2/supinvoice-approval/supinv-approval-list/supinv-approval-list.component';
import { PurchaseListComponent } from './purchase/purchase/purchaselist/purchaselist.component';
import { AddPurchaseComponent } from './purchase/purchase/add-purchase/add-purchase.component';
import { PurchaseViewComponent } from './purchase/purchase/purchase-view/purchase-view.component';
import { PurchaseApprovalComponent } from './purchase/purchase-approval/purchase-approval-list/purchase-approval-list.component';
import { ProductComponent } from './masters/product/product.component';
import { SupplierComponent } from './masters/supplier/supplier.component';
import { CustomerComponent } from './masters/customer/customer.component';
import { UserComponent } from './masters/user/user.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { DivreportComponent } from './reports/divreport/divreport.component';

import { AddProfileComponent } from './profile/add-profile/add-profile.component';
import { AddRoleComponent } from './profile/add-role/add-role.component';

import { DrCrApprovalComponent } from './drcrnote/dc-approval/dc-approval-list/dc-approval-list.component';
import { LogComplaintComponent } from './feedback/log-complaint/log-complaint.component';
import { ResolveComplaintComponent } from './feedback/resolve-complaint/resolve-complaint.component';
import { MsgBoardComponent } from './messageboard/message-board.component';
import { InitPageComponent } from './initiallogin/initpage.component';
import { CalendarComponent } from './masters/calendar/calendar.component';
import { SearchComponent } from './search/search.component';
import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
import { DashboardV2Component } from './dashboardv2/dashboardv2.component';
import { DashboardV3Component } from './dashboardv3/dashboardv3.component';
import { PriceListComponent } from './masters/pricelist/pricelist.component';
import { CollectionComponent } from './gstr1/collection/collection.component';
import { PaymentComponent } from './gstr2/payment/payment.component';
import { ExpenseComponent } from './masters/expense/expense.component';
import { ExpenseEntryComponent } from './masters/expense_entry/expense_entry.component';
import { CustPriceListComponent } from './masters/custpricelist/custpricelist.component';
import { Sc_CustomerComponent } from './studiocurtains/sc_masters/sc_customer/sc_customer.component';
import { Sc_SupplierComponent } from './studiocurtains/sc_masters/sc_supplier/sc_supplier.component';
import { Sc_ProductComponent } from './studiocurtains/sc_masters/sc_product/sc_product.component';
import { Sc_TailorComponent } from './studiocurtains/sc_masters/sc_tailor/sc_tailor.component';
import { Sc_SalespersonComponent } from './studiocurtains/sc_masters/sc_salesperson/sc_salesperson.component';
import { Sc_MeasurementComponent } from './studiocurtains/sc_masters/sc_measure/sc_measure.component';
import { Sc_InvoicelistComponent } from './studiocurtains/sc_custinv/sc_invoicelist/sc_invoicelist.component';
import { Sc_AddInvoiceComponent } from './studiocurtains/sc_custinv/sc_add-inv/sc_add-inv.component';
import { Sc_InvoiceViewComponent } from './studiocurtains/sc_custinv/sc_inv-view/sc_inv-view.component';
import { Sc_GenerateInvoiceComponent } from './studiocurtains/sc_custinv/sc_inv-print/sc_inv-print.component';
import { Sc_EditInvoiceComponent } from './studiocurtains/sc_custinv/sc_edit-inv/sc_edit-inv.component';
import { Sc_SupInvlistComponent } from './studiocurtains/sc_supinv/sc_sup_invlist/sc_sup_invlist.component';
import { Sc_SupCreateInvComponent } from './studiocurtains/sc_supinv/sc_sup_createinv/sc_sup_createinv.component';
import { Sc_SupReceiveInvComponent } from './studiocurtains/sc_supinv/sc_sup_receiveinv/sc_sup_receiveinv.component';
import { Sc_SupAddInvComponent } from './studiocurtains/sc_supinv/sc_sup_addinv/sc_sup_addinv.component';
import { Sc_SupEditInvComponent } from './studiocurtains/sc_supinv/sc_sup_editinv/sc_sup_editinv.component';
import { Sc_CustReportComponent } from './studiocurtains/sc_reports/sc_custreport/sc_custreport.component';
import { Sc_SupReportComponent } from './studiocurtains/sc_reports/sc_supreport/sc_supreport.component';
import { Sc_TailorReportComponent } from './studiocurtains/sc_reports/sc_tailorrep/sc_tailorrep.component';
import { Sc_PaymentRepComponent } from './studiocurtains/sc_reports/sc_paymentrep/sc_paymentrep.component';
import { Sc_InvoiceEntryPrintComponent } from './studiocurtains/sc_custinv/sc_inv-entry-print/sc_inv-entry-print.component';
import { Sc_CreateInvoiceComponent } from './studiocurtains/sc_custinv/sc_create-inv/sc_create-inv.component';
import { Sc_CmpltdInvlistComponent } from './studiocurtains/sc_custinv/sc_cmpltdinvlist/sc_cmpltdinvlist.component';
import { Sc_SupInstockComponent } from './studiocurtains/sc_supinv/sc_sup_instock/sc_sup_instock.component';
import { Sc_RunningInvlistComponent } from './studiocurtains/sc_custinv/sc_runninginvlist/sc_runninginvlist.component';

import { TemplateComponent } from './masters/template/template.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'forgotpassword', component: ForgotpasswordComponent },
  //{ path: '', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: '', canActivate: [AuthGuard], pathMatch: 'full', redirectTo: 'initpage' },
    { path: 'initpage', component: InitPageComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    
    { path: 'resetpassword', component: ResetPasswordComponent },
    { path: 'dashboardv2', component: DashboardV2Component, canActivate: [AuthGuard] },
    { path: 'dashboardv3', component: DashboardV3Component, canActivate: [AuthGuard] },
   
    { path: 'collection', component: CollectionComponent, canActivate: [AuthGuard] },
    { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
    { path: 'msgBoard', loadChildren: './messageboard/messageboard.module#MessageboardModule'},
    { path: 'user', loadChildren: './masters/user/user.module#UserModule'},
    { path: 'addprofile', loadChildren: './profile/add-profile/add-profile.module#AddProfileModule'},
    { path: 'addrole', loadChildren: './profile/add-role/add-role.module#AddRoleModule'},
    { path: 'department', loadChildren: './profile/department/department.module#DepartmentModule'},
    { path: 'profile', loadChildren: './profile/profile.module#ProfileModule'},
    { path: 'company', loadChildren: './company/company.module#CompanyModule'},
    { path: 'search', loadChildren: './search/search.module#SearchModule'},
    { path: 'entity', loadChildren: './masters/entity/entity.module#EntityModule'},
    { path: 'division', loadChildren: './masters/division/division.module#DivisionModule'},
    { path: 'supplier', loadChildren: './masters/supplier/supplier.module#SupplierModule'},
    { path: 'customer', loadChildren: './masters/customer/customer.module#CustomerModule'},
    { path: 'product', loadChildren: './masters/product/product.module#ProductModule'},
    { path: 'pricelist', loadChildren: './masters/pricelist/pricelist.module#PriceListModule'},
    { path: 'calendar', loadChildren: './masters/calendar/calendar.module#CalendarModule'},
    { path: 'expense', loadChildren: './masters/expense/expense.module#ExpenseModule'},
    { path: 'expenseentry',loadChildren: './masters/expense_entry/expense_entry.module#ExpenseEntryModule'},
    { path: 'addpurchase/:id', loadChildren: './purchase/purchase/add-purchase/add-purchase.module#AddPurchaseModule'},
    { path: 'apurchase', loadChildren: './purchase/purchase.module#PurchaseModule'},
    { path: 'adddrcr/:id', loadChildren: './drcrnote/debitcredit/add-drcr/add-drcr.module#AddDrcrModule' },
    { path: 'drcrinvoices', loadChildren: './drcrnote/debitcredit/drcrlist/drcrlist.module#DrcrlistModule'},
    { path: 'dcapproval', loadChildren: './drcrnote/dc-approval/dc-approval-list/dc-approval.module#DcApprovalModule'},
    { path: 'drcrview/:id', loadChildren: './drcrnote/debitcredit/drcr-view/drcr-view.module#DrcrViewModule'},
    { path: 'note', loadChildren: './drcrnote/drcrnote.module#DrCrNoteModule'},
    { path: 'addginvoices/:id', loadChildren: './gstr1/ginvoice/add-ginvoice/add-ginvoice.module#AddGinvoiceModule'},
    { path: 'ginvoices', loadChildren: './gstr1/ginvoice/ginvoicelist/ginvoicelist.module#GinvoicelistModule'},
    { path: 'ainvoices', loadChildren: './gstr1/gstr1.module#Gstr1Module'},
    { path: 'invoices', loadChildren: './gstr1/invoice/invoice.module#InvoiceModule'},
    { path: 'addinvoices/:id', loadChildren:'./gstr1/invoice/add-invoice/add-invoice.module#AddInvoiceModule' },
    { path: 'invoices/:id', loadChildren:'./gstr1/invoice/invoice-view/invoice-view.module#InvoiceViewModule' },
    { path: 'gstrUpload', loadChildren: './gstr2/gstr2a/gstr-upload.module#GstrUploadModule'},
    { path: 'supinvoices', loadChildren: './gstr2/supinvoice/supinvoicelist/supinvoicelist.module#SupinvoicelistModule'},
    { path: 'supainvoices', loadChildren: './gstr2/gstr2.module#Gstr2Module'},
    { path: 'suprinvoices', loadChildren: './gstr2/suprinvoice/suprinvoicelist/suprinvoicelist.module#SuprinvoicelistModule' },
    { path: 'suprinvoices/:id', loadChildren:'./gstr2/suprinvoice/suprinvoice-view/suprinvoice-view.module#SuprinvoiceViewModule' },
    { path: 'supaddrinvoices/:id', loadChildren: './gstr2/suprinvoice/supadd-rinvoice/supadd-rinvoice.module#SupaddRinvoiceModule'},
    //{ path: 'supaddinvoices/:id', loadChildren: './gstr2/supinvoice/supadd-invoice/supadd-invoice.module#SupaddInvoiceModule'},
    { path: 'purchase', loadChildren: './purchase/purchase/purchaselist/purchaselist.module#PurchaselistModule' },
    { path: 'purchase/:id', loadChildren:'./purchase/purchase/purchase-view/purchase-view.module#PurchaseViewModule' },
    { path: 'reports', loadChildren: './reports/reports.module#ReportsModule' },
    { path: 'divreports', loadChildren: './reports/divreport/divreport.module#DivreportModule' },
    { path: 'logcomplaint', loadChildren: './feedback/feedback.module#FeedbackModule'},
    { path: 'resolvecomplaint', loadChildren: './feedback/resolve-complaint/resolve-complaint.module#ResolveComplaintModule' },
    { path: 'custpricelist', loadChildren: './masters/custpricelist/custpricelist.module#CustPriceListModule' },
    
    { path: 'sc_customer', loadChildren: './studiocurtains/sc_masters/sc_customer/sc_customer.module#Sc_CustomerModule' },
    { path: 'sc_supplier', loadChildren: './studiocurtains/sc_masters/sc_supplier/sc_supplier.module#Sc_SupplierModule' },
    { path: 'sc_product', loadChildren: './studiocurtains/sc_masters/sc_product/sc_product.module#Sc_ProductModule' },
    { path: 'sc_tailor', loadChildren: './studiocurtains/sc_masters/sc_tailor/sc_tailor.module#Sc_TailorModule' },
    { path: 'sc_salesperson', loadChildren: './studiocurtains/sc_masters/sc_salesperson/sc_salesperson.module#Sc_SalespersonModule' },
    { path: 'sc_measure', loadChildren: './studiocurtains/sc_masters/sc_measure/sc_measure.module#Sc_MeasurementModule' },
    { path: 'sc_invoices', loadChildren: './studiocurtains/sc_custinv/sc_invoicelist/sc_invoicelist.module#Sc_InvoicelistModule' },
    { path: 'sc_runninginvoices', loadChildren: './studiocurtains/sc_custinv/sc_runninginvlist/sc_runninginvlist.module#Sc_RunningInvlistModule' },
    { path: 'sc_addinvoices/:id', loadChildren: './studiocurtains/sc_custinv/sc_add-inv/sc_add-inv.module#Sc_AddInvoiceModule' },
    { path: 'sc_invoices/:id', loadChildren: './studiocurtains/sc_custinv/sc_inv-view/sc_inv-view.module#Sc_InvoiceViewModule' },
    { path: 'sc_editinvoices/:id', loadChildren: './studiocurtains/sc_custinv/sc_edit-inv/sc_edit-inv.module#Sc_EditInvoiceModule' },
    { path: 'sc_genInvoice/:id', loadChildren: './studiocurtains/sc_custinv/sc_inv-print/sc_inv-print.module#Sc_GenerateInvoiceModule' },
    { path: 'sc_supinvoices', loadChildren: './studiocurtains/sc_supinv/sc_sup_invlist/sc_sup_invlist.module#Sc_SupInvlistModule' },
    { path: 'sc_supcreateinv', loadChildren: './studiocurtains/sc_supinv/sc_sup_createinv/sc_sup_createinv.module#Sc_SupCreateInvModule' },
    { path: 'sc_supreceiveinv', loadChildren: './studiocurtains/sc_supinv/sc_sup_receiveinv/sc_sup_receiveinv.module#Sc_SupReceiveInvModule' },
    { path: 'sc_supaddinvoices/:id', loadChildren: './studiocurtains/sc_supinv/sc_sup_addinv/sc_sup_addinv.module#Sc_SupAddInvModule' },
    { path: 'sc_supeditinvoices/:id', loadChildren: './studiocurtains/sc_supinv/sc_sup_editinv/sc_sup_editinv.module#Sc_SupEditInvModule' },
    { path: 'sc_custreport/:id', loadChildren: './studiocurtains/sc_reports/sc_custreport/sc_custreport.module#Sc_CustReportModule' },
    { path: 'sc_custreport', loadChildren: './studiocurtains/sc_reports/sc_custreport/sc_custreport.module#Sc_CustReportModule' },
    { path: 'sc_supreport', loadChildren: './studiocurtains/sc_reports/sc_supreport/sc_supreport.module#Sc_SupReportModule' },
    { path: 'sc_tailorreport', loadChildren: './studiocurtains/sc_reports/sc_tailorrep/sc_tailorrep.module#Sc_TailorReportModule' },
    { path: 'sc_paymentrep', loadChildren: './studiocurtains/sc_reports/sc_paymentrep/sc_paymentrep.module#Sc_PaymentRepModule' },
    { path: 'sc_inventryprint/:id', loadChildren: './studiocurtains/sc_custinv/sc_inv-entry-print/sc_inv-entry-print.module#Sc_InvoiceEntryPrintModule' },
    { path: 'sc_createinvoices', loadChildren: './studiocurtains/sc_custinv/sc_create-inv/sc_create-inv.module#Sc_CreateInvoiceModule' },
    { path: 'sc_cmpltdinvoices', loadChildren: './studiocurtains/sc_custinv/sc_cmpltdinvlist/sc_cmpltdinvlist.module#Sc_CmpltdInvlistModule' },
    { path: 'sc_supinstock', loadChildren: './studiocurtains/sc_supinv/sc_sup_instock/sc_sup_instock.module#Sc_SupInstockModule' },
    { path: 'template', loadChildren: './masters/template/template.module#TemplateModule'},
    //{ path: '**', redirectTo: 'dashboard' }
    { path: '**', redirectTo: 'initpage' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { useHash: true })
        //RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
