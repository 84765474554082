﻿import { AuthData } from './../auth.model';
import { User } from './../../masters/user/user.model';
import { APIURLS } from './../../shared/api-url';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from './../../app.component';
import { HttpService } from './../../shared/http-service';
import { AuthService } from '../auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  userList: User[];
  loginData = { username: '', password: '' };
  isLoading: boolean = false;
  public errMsg: string = '';
  public errMsg1: string = '';
  public emailMsg1: string = '';
  
  constructor(private authservice: AuthService,
    private appService: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService) { }

  ngOnInit() {
    this.authservice.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  onLogin() {
    this.isLoading = true;
    this.authservice.login(this.loginData.username, this.loginData.password).then((data: any) => {
        this.isLoading = false;
        this.authservice.authData = new AuthData(true, data.userid, this.loginData.username, data.firstName, data.lastName, data.usr_country, data.email, data.roles, data.access_token, data.isapprover, data.usr_level, data.roleId, data.status, (data.expires_in * 1000) + new Date().getTime());
      localStorage.setItem('currentUser', JSON.stringify(this.authservice.authData));
      console.log(this.authservice.authData)
      if (this.authservice.authData.isAuth == true) {
          this.router.navigate([this.returnUrl]);
          //load app-root on login
          this.appService.ngOnInit();
          //location.reload();
      }

    }).catch((error: any) => {
      this.isLoading = false;
      this.errMsg = error.errMsg;
    });
  }

  validateUser(username) {
    let connection: any;
    if (username == undefined || username == '')
    {
        this.errMsg1 = "Enter UserId";
        this.emailMsg1 = '';
    }
    else {
      this.isLoading = true;
      this.httpService.forgotpassword(APIURLS.BR_FORGOTPASSWORD_API, username, this.loginData).then((data: any) => {
        this.isLoading = false;
        if (data.status == 'SUCCESS') {
            this.errMsg1 = ''; 
            this.emailMsg1= data.message;
        }
        if (data.status == 'ERROR') {
            this.errMsg1 = data.message;
            this.emailMsg1 = '';
        }
      }).catch(error => {
        this.isLoading = false;
        this.errMsg1 = error.message;
        this.emailMsg1 = '';
      });

    }
  }

}
