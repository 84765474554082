﻿import { APIURLS } from './../shared/api-url';
import { Router } from '@angular/router';
import { HttpService } from './../shared/http-service';
import { AppComponent } from './../app.component';
import { Component, OnInit } from '@angular/core';
import * as _ from "lodash";
declare var jQuery: any;

@Component({
  selector: 'app-initpage',
  templateUrl: './initpage.component.html',
  styleUrls: ['./initpage.component.css']
})
export class InitPageComponent implements OnInit {
    title = 'app';
    dshbrdRouteList: any[];
    dshbrdV1: any[];
    dshbrdV2: any[];
    dshbrdV3: any[];
  constructor(private appService: AppComponent, private router: Router, private httpService: HttpService) { }

  ngOnInit() {
      this.title = 'Loading';
      this.routetoDashboard();
      //this.router.navigate(["/dashboard"]);
      //location.reload();
      
  }

  routetoDashboard() {
        this.httpService.get(APIURLS.BR_MASTER_PROFILEFORMMAIN_API).then((data: any) => {
            if (data.status == 'SUCCESS') {
                this.dshbrdRouteList = data.formMaintenanceList;
                this.dshbrdV1 = _.filter(this.dshbrdRouteList, function (obj) { if (obj.menuId == "1" && obj.subMenuId == "13") return obj; });
                this.dshbrdV2 = _.filter(this.dshbrdRouteList, function (obj) { if (obj.menuId == "1" && obj.subMenuId == "34") return obj; });
                this.dshbrdV3 = _.filter(this.dshbrdRouteList, function (obj) { if (obj.menuId == "1" && obj.subMenuId == "35") return obj; });
                if (this.dshbrdV1.length > 0) {
                    this.router.navigate(["/dashboard"]);
                    //location.reload();
                }
                else if (this.dshbrdV2.length > 0) {
                    this.router.navigate(["/dashboardv2"]);
                    //location.reload();
                }
                else if (this.dshbrdV3.length > 0) {
                    this.router.navigate(["/dashboardv3"]);
                    //location.reload();
                }
            }
        }).catch(error => {
            this.dshbrdRouteList = [];
        });
    }
  //ngAfterViewInit() {
  //    debugger;
  //   // this.router.navigate(["/dashboard"]);
  //}
  //onloadevent() {
  //   // debugger;
  //    //this.router.navigate(["/dashboard"]);
  //}
 
  //ngAfterViewChecked() {
  //   // debugger;
  //   // this.router.navigate(["/dashboard"]);
  //    // Component views have been checked
  //}

}
