﻿import { APIURLS } from './../shared/api-url';
import { ExcelService } from './../shared/excel-service';
import { HttpService } from '../shared/http-service';
import { AppComponent } from './../app.component';
import { Component, OnInit } from '@angular/core';
import * as alaSQLSpace from 'alasql';
import * as _ from "lodash";
declare var jQuery: any;
@Component({
  selector: 'app-dashboardv3',
  templateUrl: './dashboardv3.component.html',
  styleUrls: ['./dashboardv3.component.css']
})
export class DashboardV3Component implements OnInit {
  public tableWidget: any;
  dashboardData: any;
  feedbackList: any[];
  reportSource: number;
  monthSelected: number = 0;
  SelFilter: number = 0;
  startDate: Date = new Date();
  endDate: Date = new Date();
  isChecked: boolean = false;
  tax1: any[];
  errMsg: string = "";
  isLoading: boolean = false;
  errMsgPop: string = "";
  isLoadingPop: boolean = false;
  errMsgStrt: string = "";
  errMsgEnd: string = "";
  isValidate: boolean = true;
  constructor(private appService: AppComponent, private httpService: HttpService, private excelService: ExcelService) { }

  private initDatatable(): void {
    let exampleId: any = jQuery('#complaintTable');
    this.tableWidget = exampleId.DataTable();
  }

  private reInitDatatable(): void {
    if (this.tableWidget) {
      this.tableWidget.destroy()
      this.tableWidget = null
    }
    setTimeout(() => this.initDatatable(), 0)
  }

  ngAfterViewInit() {
    this.initDatatable()
  }

  ngOnInit() {
    this.getDashBoardData();
  }

  getDashBoardData() {
    this.errMsg = "";
    this.isLoading = true;
    this.httpService.get(APIURLS.BR_DASHBOARD_DATA).then((data: any) => {
      this.isLoading = false;
      if (data.status == 'SUCCESS') {
        //debugger;
          this.dashboardData = data;
          this.SelFilter = 0;
        this.tax1= data.currentMonthOutward;
      }
    }).catch(error => {
      this.isLoading = false;

    });
  }

  validateForm() {
      //this.errMsg = "";
      if (this.startDate == undefined || this.startDate == null || this.startDate.toString() == "") {
          this.errMsgStrt = "please select From date.";
          this.isValidate = false;
          return this.isValidate;
      }

      if (this.errMsgStrt == 'Enter valid From Date') {
          this.errMsgStrt = "Enter valid From Date.";
          this.isValidate = false;
          return this.isValidate;
      }
      if (this.endDate == undefined || this.endDate == null || this.endDate.toString() == "") {
          this.errMsgEnd = "please select To date.";
          this.isValidate = false;
          return this.isValidate;
          //return false;
      }

      if (this.errMsgEnd == 'Enter valid To Date') {
          this.errMsgEnd = "Enter valid To Date.";
          this.isValidate = false;
          return this.isValidate;
          //return false;
      }
      else {
          this.isValidate = true;
          return this.isValidate;
      }
  }

  onGetExcel() {
    //alert(this.isChecked) ;
    //this.excelService.exportAsExcelFile([{ 'abc': 1, 'xyz': 'hello' }, { 'abc': 2, 'xyz': 'hello2' }, { 'abc': 3, 'xyz': 'hello3' }], 'abcxcel');

      this.errMsgPop = "";
      this.isValidate = this.validateForm();
      if (!this.isValidate) {
          this.errMsgStrt = this.errMsgStrt;
          this.errMsgEnd = this.errMsgEnd;
      }
      else {
          this.isLoadingPop = true;
          let reqData = { 'reverseCharge': this.isChecked ? -1 : this.reportSource, 'fdate': this.startDate, 'tdate': this.endDate };
          this.httpService.post(APIURLS.BR_DASHBOARD_EXCEL_REPORT, reqData).then((data: any) => {
              this.isLoadingPop = false;
              if (data.status == 'SUCCESS') {
                  var fData = [];
                  if (data.excelItemList.length > 0) {
                      //var header = false;
                      var hObj = {};
                      // for (var i = 0; i <= data.excelItemList.length; i++) {
                      //   var myObj = {};
                      //   var dataObj = data.excelItemList[i];
                      //   for (var key in dataObj) {
                      //     if (dataObj.hasOwnProperty(key)) {
                      //       myObj[key.toUpperCase()] = dataObj[key];
                      //       if (header == false)
                      //         hObj[key.toUpperCase()] = key.toUpperCase();
                      //     }
                      //   }
                      //   if (header == false) {
                      //     fData.push(hObj);
                      //     header = true;
                      //   }
                      //   fData.push(myObj);
                      // }
                      //this.excelService.exportAsExcelFile(fData, 'excelReport');
                      for (var key in data.excelItemList[0]) {
                          if (data.excelItemList[0].hasOwnProperty(key)) {
                              hObj[key] = key.toUpperCase();
                          }
                      }
                      data.excelItemList.splice(0, 0, hObj);
                      var opts = [{ sheetid: 'Data', headers: false }];
                      var res = alasql('SELECT INTO XLSX("report_export_' + new Date().getTime() + '.xlsx",?) FROM ?', [opts, [data.excelItemList]]);
                  }
                  else
                      this.errMsgPop = "No invoice data to download.";
              }
              else {
                  this.errMsgPop = data.message;
              }
          }).catch(error => {
              this.isLoadingPop = false;
              this.errMsgPop = "Error, Connecting Server";
              //this.entityList = [];
          });
      }
  }


  onDatePopup(source) {
    this.errMsgPop = "";
    this.isLoadingPop = false;
    this.reportSource = source;
    var date = new Date();
    this.startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.isChecked = false;
    // jQuery('input[type="checkbox"].minimal').iCheck({
    //   checkboxClass: 'icheckbox_minimal-blue'
    // });

    jQuery("#dateModal").modal('show');
  }

  openFeedbackSearch(pi: any) {
    jQuery("#feedbackModal").modal('show');
    this.getComplaintMasterList(pi.divId);
  }

  getComplaintMasterList(divid: number) {
    this.errMsg = "";
    this.isLoading = true;
    this.httpService.getById(APIURLS.BR_DASHBOARD_FEEDBACK_API, divid).then((data: any) => {
      this.isLoading = false;
      if (data.status == 'SUCCESS') {
        this.feedbackList = data.feedbackList;
       
      }
      this.reInitDatatable();
    }).catch(error => {
      this.isLoading = false;
      this.feedbackList = [];
    });
  }

  onMonthSelected(item: number) {
      this.monthSelected = item;
      this.errMsg = "";
      this.isLoading = true;
      this.httpService.getById(APIURLS.BR_DASHBOARDFILTER_DATA_API, this.monthSelected).then((data: any) => {
          this.isLoading = false;
          if (data.status == 'SUCCESS') {
              this.dashboardData = data;
              this.tax1 = data.currentMonthOutward;

          }
          this.reInitDatatable();
      }).catch(error => {
          this.isLoading = false;
          this.dashboardData = [];
      });
  }

  setStartDate(sDate: any) {
      //this.purchase.po_date = Number(new Date(poDate));
      this.errMsgStrt = '';
      var date_regex = /^((((19|[2-9]\d)\d{2})\-(0[13578]|1[02])\-(0[1-9]|[12]\d|3[01]))|(((19|[2-9]\d)\d{2})\-(0[13456789]|1[012])\-(0[1-9]|[12]\d|30))|(((19|[2-9]\d)\d{2})\-02\-(0[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))\-02\-29))$/g;
      if ((date_regex.test(sDate))) {
          this.startDate = sDate;
      }
      else if (sDate == "") {
          this.errMsgStrt = 'Enter valid From Date';
      }
  }

  setEndDate(eDate: any) {
      //this.purchase.po_date = Number(new Date(poDate));
      this.errMsgEnd = '';
      var date_regex = /^((((19|[2-9]\d)\d{2})\-(0[13578]|1[02])\-(0[1-9]|[12]\d|3[01]))|(((19|[2-9]\d)\d{2})\-(0[13456789]|1[012])\-(0[1-9]|[12]\d|30))|(((19|[2-9]\d)\d{2})\-02\-(0[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))\-02\-29))$/g;
      if ((date_regex.test(eDate))) {
          this.endDate = eDate;
      }
      else if (eDate == "") {
          this.errMsgEnd = 'Enter valid To Date';
      }
  }


  //getComplaintMasterList1(divid: number) {
  //  this.errMsg = "";
  //  this.isLoading = true;
  //  this.httpService.get(APIURLS.BR_MASTER_FEEDBACK_API).then((data: any) => {
  //    this.isLoading = false;
  //    if (data.status == 'SUCCESS') {
  //      //this.feedbackList = data.feedbackList;
  //      this.feedbackList = _.filter(data.feedbackList, function (o: any) {
        
  //        if (o.divid == divid && o.status == 0)
  //        {
  //          return o;
  //        }
  //      });
  //    }
  //    this.reInitDatatable();
  //  }).catch(error => {
  //    this.isLoading = false;
  //    this.feedbackList = [];
  //    });
  //}
}
