﻿export class Supplier {
    constructor(
        public venderid: number,
        public cmpid: number,
        public vend_name: string,
        public vend_addr_ln1: string,
        public vend_addr_ln2: string,
        public vend_city: string,
        public vend_state: string,
        public vend_state_code: string,
        public vend_email: string,
        public vend_pin: number,
        public vend_ctry: string,
        public vend_type: string,
        public vend_group: string,
        public vend_region: string,
        public vend_currency: string,
        public vend_gstin: string,
        public vend_pan: string,
        public vend_website: string,
        public vend_status: string,
        public vend_phone: string,
        public vend_contact_name: string,
        public vend_contact_no: string,
        public vend_credit_terms: string,
        public vend_credit_limit: number,
        public vend_comments: string,
        public vend_create_by: string,
        public vend_update_by: string,
        public vend_balance: number,
        public status: boolean
            ) { }
       
}
