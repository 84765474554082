﻿export class Customer {
    constructor(
        public custid: number,
        public cmpid: number,
        public cust_name: string,
        public cust_addr_ln1: string,
        public cust_addr_ln2: string,
        public cust_city: string,
        public cust_state_code: string,
        public cust_state: string,
        public cust_email: string,
        public cust_pin: number,
        public cust_ctry: string,
        public cust_type: string,
        public cust_group: string,
        public cust_region: string,
        public cust_currency: string,
        public cust_gstin: string,
        public cust_pan: string,
        public cust_cin: string,
        public cust_website: string,
        public cust_status: string,
        public cust_phone: string,
        public cust_contact_name: string,
        public cust_contact_no: string,
        public cust_credit_terms: string,
        public cust_credit_limit: number,
        public cust_comments: string,
        public cust_create_by: string,
        public cust_update_by: string,
        public cust_balance: number,
        public status: boolean
            ) { }
       
}
