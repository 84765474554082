﻿import { APIURLS } from './../../../shared/api-url';
import { AppComponent } from './../../../app.component';
import { Product } from './../product.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {
  [x: string]: any;
  productItem: Product = new Product(0, 0, '', '', '', '', 0, '', false, '', true, 0, 0, 0, 0, '', '','');

  isLoadingPop: boolean = false;
  errMsgPop: string = "";
  isEdit: boolean = false;

  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private appService: AppComponent) { }

  ngOnInit() {
  }


  onSaveProduct(status: boolean) {
    this.errMsgPop = "";
    this.isLoadingPop = true;
    //this.productItem.en_date_reg = Number(new Date(this.regDate));
    let connection: any;
    if (!this.isEdit)
      connection = this.httpService.post(APIURLS.BR_MASTER_PRODUCT_API, this.productItem);
    else
      connection = this.httpService.put(APIURLS.BR_MASTER_PRODUCT_API, this.productItem.pid, this.productItem);

    connection.then((data: any) => {
      this.isLoadingPop = false;
      if (data.status == 'SUCCESS') {
        //jQuery("#myModal").modal('hide');
        this.getProductMasterList();
      }
      else {
        this.errMsgPop = data.message;
      }
    }).catch(error => {
      this.isLoadingPop = false;
      this.errMsgPop = 'Error saving entity data..';
    });
  }

   close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

}
