﻿import { Customer } from './../../masters/customer/customer.model';
import { Collection } from './collection.model';
import { AppComponent } from './../../app.component';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpService } from './../../shared/http-service';
import { APIURLS } from './../../shared/api-url';
import * as _ from "lodash";
declare var jQuery: any;


@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.css']
})
export class CollectionComponent implements OnInit {
    public custTableWidget: any;
    public colTableWidget: any;
    customerList: any[];
    divisionList: any[];
    submitDisable: boolean = false;
    isLoading: boolean = false;
    hideColTable: boolean = true;
    divisionConstList: any[];
    customerConstList: any[];
    divisionConst: string;
    customerConst: string;
    typeTabHeader: string;
    prodTabHeader: string;
    descTabHeader: string;
    hsnTabHeader: string;
    qtyTabHeader: string;
    hsnTitle: string;
    basicValTabHeader: string;
    basicAmtTabHeader: string;
    totValTabHeader: string;
    amtCollTabHeader: string;
    addInvHeaderList: any[];
    addInvHeaderItem: any[];
    priceInvHeaderList: any[];
    priceInvTabHeaderList: any[];
    cusInvHeaderList: any[];
    collectionList: any[];
    //sdivison: any;
    //scust: any;
    errMsgDate: string = "";
    errMsg: string = "";
    errMsgPAN: string = "";
    errMsgGSTIN: string = "";
    isLoadingPop: boolean = false;
    isValidgstin: boolean = false;
    errMsgPop: string = "";
    errMsgPopInv: string = "";
    modetitle: string = "Add ";
    col_doc_date: Date = new Date();
    inv_date: Date = new Date();
    invoice: any;
    collection: any;

    ////////////
    customerItem: Customer = this.customerItem = new Customer(0, 0, '', '', '', '', '', '', '', 0, '', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, '', '', '', 0, true);
    constructor(private appService: AppComponent, private httpService: HttpService,
        private route: ActivatedRoute,
        private router: Router, vcr: ViewContainerRef) {
        //this.toastr.setRootViewContainerRef(vcr);
    }

    private initDatatable(): void {
        let exampleId1: any = jQuery('#custTable');
        let exampleId2: any = jQuery('#productTable'); 
        let exampleId3: any = jQuery('#InvColTable');
        this.custTableWidget = exampleId1.DataTable();
        this.colTableWidget = exampleId3.DataTable();
    }

    private reInitDatatable(): void {
        if (this.custTableWidget) {
            this.custTableWidget.destroy()
            this.custTableWidget = null
        }
        if (this.colTableWidget) {
            this.colTableWidget.destroy()
            this.colTableWidget = null
        }
        setTimeout(() => this.initDatatable(), 0)
    }

    ngAfterViewInit() {
        this.initDatatable()
    }

    ngOnInit() {
        this.getAddInvHeaders();
        this.getDivisionConst();
        this.getCustomerConst();
        //this.route.params.subscribe(params => { this.invid = params['id']; });
        //if (this.invid > 0) {
        //    this.modetitle = "Update ";
        //    this.invoice = { inv_division: {}, inv_exp_dom: 'Domestic', inv_ord_nbr: '', inv_date: new Date(), inv_customer: {}, inv_supply_type: -1, inv_pt_type: 0, inv_total_value: 0.0, inv_total_collected: 0.0, inv_total_sgst: 0.0, inv_total_cgst: 0.0, inv_total_igst: 0.0, inv_total_cess: 0.0, inv_port_code: '', inv_remarks: '', inv_contact_name:'', inv_gst_pay: 1, invoiceDetails: [] };
        //    this.getInvoiceById();
        //}
        //else {
        //    this.invoice = { inv_division: {}, inv_exp_dom: 'Domestic', inv_ord_nbr: '', inv_date: new Date(), inv_customer: {}, inv_supply_type: -1, inv_pt_type: 0, inv_total_value: 0.0, inv_total_collected: 0.0, inv_total_sgst: 0.0, inv_total_cgst: 0.0, inv_total_igst: 0.0, inv_total_cess: 0.0, inv_port_code: '', inv_remarks: '', inv_contact_name:'', inv_gst_pay: 1, invoiceDetails: [] };
        //}
        this.collection = { col_division: {}, col_customer: {}, col_actual_collected: 0.0, col_remarks: '', col_payment_mode:'', col_doc_date: new Date(), collectionDetails: []  };

        this.getDivision();
    }

    openCustSearch() {
        jQuery("#myModalCust").modal('show');
        this.getInvCustHeaders();
        this.getCustomerList();
    }

    onPaymentFilterChange(payMode:any) {
        this.collection.col_payment_mode = payMode;
    }

    
    validateForm() {
        this.errMsg = "";
        if (this.collection.col_division.divid == undefined || this.collection.col_division.divid == undefined || this.collection.col_division.divid == 0) {
            this.errMsg = "please select division";
            return false;
        }
        
        if (this.collection.col_payment_mode == undefined || this.collection.col_payment_mode == "") {
            this.errMsg = "please select Payment Mode.";
            return false;
        }

        if (this.collection.col_customer == undefined || this.collection.col_customer.cust_state_code == undefined || this.collection.col_customer.cust_state_code.length == 0) {
            this.errMsg = "please select customer or selected customer not associated with state.";
            return false;
        }

        //if (this.collection.inv_customer.cust_name == "General Customer" && this.collection.inv_contact_name=="") {
        //    this.errMsg = "please enter customer name.";
        //    return false;
        //}
        //debugger;

        if (this.col_doc_date == undefined || this.col_doc_date == null || this.col_doc_date.toString() == "") {
            
            this.errMsgDate = "please select collection date.";
            return false;
        }

        if (this.errMsgDate == 'Enter valid Date') {
            this.errMsgDate = "Enter valid Date.";
            return false;
        }

        //if (this.collection.inv_ref_no == undefined || this.collection.inv_ref_no.length == 0) {
        //    this.errMsg = "please select collection reference no.";
        //    return false;
        //}

        if (this.collection.col_actual_collected == undefined || this.collection.col_actual_collected == 0) {
            this.errMsg = "please enter collection amount.";
            return false;
        }


        //var notValidQyt = _.filter(this.invoice.invoiceDetails, function (obj: any) { return parseInt(obj.invd_qty) == NaN || obj.invd_qty < 0; });
        //var notvalidePrice = _.filter(this.invoice.invoiceDetails, function (obj: any) { return parseInt(obj.invd_price) == NaN || obj.invd_price < 0; });
        //if (notValidQyt || this.invoice.inv_total_cess<0 || notvalidePrice) {
        //    this.errMsg = "please feed proper cess,price and quatity value.";
        //    return false;
        //}
        return true;
    }

    getCollectionTable() {
        this.hideColTable = false;
        //this.reInitDatatable();
    }

    cancelCollection() {
       // debugger;
        

        this.errMsg = "";
        this.errMsgPopInv = "";
        this.hideColTable = true;
        this.collection.col_division = [];
        this.divisionList = [];
        this.ngOnInit();
        this.submitDisable = false;
        
        //this.errMsg = "";
        //this.errMsgPopInv = "";
        //this.hideColTable = true;
        //this.router.navigateByUrl("/collection");
    }

    saveCollection() {
        this.errMsg = "";
        this.errMsgPopInv = "";
        if (!this.validateForm()) {
            return;
        }
        //    this.isLoadingPop = true;
        this.collection.col_doc_date = Number(new Date(this.col_doc_date));
        let connection: any;
        connection = this.httpService.post(APIURLS.BR_MASTER_COLLECTION_API, this.collection);
        connection.then((data: any) => {
            this.submitDisable = true;
            //this.isLoadingPop = false;
            if (data.status == 'SUCCESS') {
                this.collection = data.collection;
                if (this.collection.collectionDetails.length>0) {
                    this.getCollectionTable();
                }
                else {
                    this.errMsgPopInv = "No Pending Invoice Records Exist. Collection amount saved as Advance";
                }
                //this.collectionList = data.collection.
                //debugger;
                //this.toastr.success(data.message+"", 'Success!');
               // this.router.navigateByUrl('/invoices');
            }
            else {
                this.isLoadingPop = false;
                this.errMsgPopInv = data.message;
            }
        }).catch(error => {
            this.isLoadingPop = false;
            this.errMsgPopInv = 'Error saving collection data..';
        });
    }

    //saveInv() {
    //    this.errMsg = "";
    //    this.errMsgPopInv = "";
    //    if (!this.validateForm()) {
    //        return;
    //    }
    //    this.isLoadingPop = true;
    //    this.invoice.inv_date = Number(new Date(this.invoice.inv_date));
    //    let connection: any;
    //    connection = this.httpService.post(APIURLS.BR_INVOICE_API, this.invoice);
    //    connection.then((data: any) => {
    //        this.isLoadingPop = false;
    //        if (data.status == 'SUCCESS') {
    //            //this.toastr.success(data.message+"", 'Success!');
    //            this.router.navigateByUrl('/invoices');
    //        }
    //        else {
    //            this.isLoadingPop = false;
    //            this.errMsgPopInv = data.message;
    //        }
    //    }).catch(error => {
    //        this.isLoadingPop = false;
    //        this.errMsgPopInv = 'Error saving invoice data..';
    //    });
    //}

    onCustSelectionChange(customer) {
        this.collection.col_customer = customer;
    }
    getAddInvHeaders() {

        this.httpService.get(APIURLS.BR_APP_INV_STRUCTURE_API).then((data: any) => {
            if (data.status == 'SUCCESS') {

                this.addInvHeaderList = data.appInvStrList;
                this.addInvHeaderList = data.appInvStrList.find(s => s.subMenuId == '39'); //_.filter(data.formData, function (obj) { if (obj.name == 'Entity') return obj; });
                this.addInvHeaderItem = _.filter(data.appInvStrList, function (obj) { if (obj.subMenuId == '39') return obj; });
                this.typeTabHeader = _.map(this.addInvHeaderItem, 'add_view_type');
                this.prodTabHeader = _.map(this.addInvHeaderItem, 'product');
                this.descTabHeader = _.map(this.addInvHeaderItem, 'description');
                this.hsnTabHeader = _.map(this.addInvHeaderItem, 'hsN_SAC');
                this.qtyTabHeader = _.map(this.addInvHeaderItem, 'quantity');
                this.basicValTabHeader = _.map(this.addInvHeaderItem, 'basicvalue_price');
                this.basicAmtTabHeader = _.map(this.addInvHeaderItem, 'basic_amount');
                this.totValTabHeader = _.map(this.addInvHeaderItem, 'total_value');
                this.amtCollTabHeader = _.map(this.addInvHeaderItem, 'amt_collected_and_Paid');

            }
        }).catch(error => {
            this.addInvHeaderList = null;
        });
    }

    getCustomerList() {

        this.isLoading = true;
        this.httpService.get(APIURLS.BR_Master_CUSTOMER_API).then((data: any) => {
            this.isLoading = false;
            if (data.status == 'SUCCESS'){
                //this.customerList = data.customerList;
                this.customerList = _.filter(data.customerList, function(o :any) { return o.status==1; });
            }
            this.reInitDatatable();
        }).catch(error => {
            this.isLoading = false;
            this.customerList = [];
        });
    }
    getDivision() {
       // this.isLoading = true;
        this.httpService.get(APIURLS.BR_MASTER_FILTERDIV_API).then((data: any) => {
           // this.isLoading = false;
            if (data.status == 'SUCCESS')
                this.divisionList = data.divisionList;
            if (this.divisionList.length == 1) {
                this.collection.col_division = this.divisionList[0];
            }
          //  this.reInitDatatable();
        }).catch(error => {
          //  this.isLoading = false;
            this.divisionList = [];
        });
    }
    setCollectionDate(colDate: any) {
        this.errMsgDate = "";
       // debugger;
        var date_regex = /^((((19|[2-9]\d)\d{2})\-(0[13578]|1[02])\-(0[1-9]|[12]\d|3[01]))|(((19|[2-9]\d)\d{2})\-(0[13456789]|1[012])\-(0[1-9]|[12]\d|30))|(((19|[2-9]\d)\d{2})\-02\-(0[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))\-02\-29))$/g;
        if ((date_regex.test(colDate))) {
            this.col_doc_date = colDate;
        }
        else if (colDate == "") {
            this.errMsgDate = 'Enter valid Date';
        }
        //if (colDate == undefined || colDate == null || colDate.toString() == "") {
        //    this.errMsg = "please select collection date.";
        //} else {
        //    this.errMsg = "";
        //    this.collection.col_doc_date = Number(new Date(colDate));
        //}
    }
    getCustomerConst() {
        this.httpService.get(APIURLS.BR_MASTER_PROFILEFORMMAIN_API).then((data: any) => {
            if (data.status == 'SUCCESS') {
                //this.urlList = data.formMaintenanceList;
                this.customerConstList = _.filter(data.formMaintenanceList, function (obj) { if (obj.menuId == "2" && obj.subMenuId == '5') return obj; });
                this.customerConst = _.map(this.customerConstList, 'name');
            }
        }).catch(error => {
            this.customerConstList = [];
        });
    }
    getDivisionConst() {
        this.httpService.get(APIURLS.BR_MASTER_PROFILEFORMMAIN_API).then((data: any) => {
            if (data.status == 'SUCCESS') {
                //this.urlList = data.formMaintenanceList;
                this.divisionConstList = _.filter(data.formMaintenanceList, function (obj) { if (obj.menuId == "2" && obj.subMenuId == '3') return obj; });
                this.divisionConst = _.map(this.divisionConstList, 'name');
            }
        }).catch(error => {
            this.divisionConstList = [];
        });
    }
    getInvCustHeaders() {
        this.httpService.get(APIURLS.BR_FORM_DATA_API).then((data: any) => {
            if (data.status == 'SUCCESS') {
                this.cusInvHeaderList = data.formDataList;
                this.cusInvHeaderList = data.formDataList.find(s => s.subMenuId == '5'); //_.filter(data.formData, function (obj) { if (obj.name == 'Entity') return obj; });
            }
        }).catch(error => {
            this.cusInvHeaderList = null;
        });
    }
}
