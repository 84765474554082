﻿import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { HttpService } from './shared/http-service';
import { AuthData } from './auth/auth.model';
import { APIURLS } from './shared/api-url';
import { Component, OnInit } from '@angular/core';
import * as _ from "lodash";
declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  messageBoardList: any[];
  urlList: any[];
  uimgList: string = "";
  cmpimg: string = "";
  cmp_name: string = "";
  masterList: any[];
  //msgBoard: any[];
  reportList: any[];
  gstr1List: any[];
  gstr2List: any[];
  //purchaseList: any[];
  dashboardV1: any[];
  dashboardV2: any[];
  dashboardV3: any[];
  searchList: any[];
  adminList: any[];
  doclist: any[];
  drcr: any[];
  feedback: any[];
  account: any[];
  urlfilter: any[];
  urlAccess: any[];
  url: string = "";
  dashboard1: boolean=false;
  dashboard2: boolean = true;
  dashboard3: boolean = true;
  loggedIn: boolean = false;
  masterHeader: string = "";
  gstr1Header: string = "";
  gstr2Header: string = "";
  drcrHeader: string = "";
  feedbackHeader: string = "";
  reportHeader: string = "";
  authdata: AuthData;
  constructor(public authService: AuthService, private router: Router, private httpService: HttpService) {
  }

  ngOnInit() {
    this.authdata = JSON.parse(localStorage.getItem('currentUser'));
    this.getFormList();
    this.getUserImg();
    this.getMsgBoardMasterList();
  }

  getUserName() {
     
    if (this.authService.authData.isAuth) {
      return this.authService.authData.firstName+' '+this.authService.authData.lastName;
    }
    else {
      return '';
    }

  }

  hideSideMenu()
  {
    if (jQuery('.control-sidebar').hasClass('control-sidebar-open')) {
      jQuery('.control-sidebar').removeClass('control-sidebar-open');
    }
  }

  openSideMenu() {
      if (jQuery('.control-sidebar').hasClass('control-sidebar-open')) {
          jQuery('.control-sidebar').removeClass('control-sidebar-open');
      }
      else {
          jQuery('.control-sidebar').addClass('control-sidebar-open');
      }
  }

  getFormList() {
    if (!this.authService.authData.isAuth) {
      this.router.navigate(["/login"]);
      console.log('getforms');
      console.log(this.authService.authData.isAuth);
    }
    else{
      this.httpService.get(APIURLS.BR_MASTER_PROFILEFORMMAIN_API).then((data: any) => {
      if (data.status == 'SUCCESS') {
          this.urlList = data.formMaintenanceList;
        this.dashboardV1 = _.filter(this.urlList, function (obj) { if (obj.menuId == "1" && obj.subMenuId == "13") return obj; });
        this.dashboardV2 = _.filter(this.urlList, function (obj) { if (obj.menuId == "1" && obj.subMenuId == "34") return obj; });
        this.dashboardV3 = _.filter(this.urlList, function (obj) { if (obj.menuId == "1" && obj.subMenuId == "35") return obj; });
        if (this.dashboardV1.length > 0) {
            this.dashboard1 = false;
            this.dashboard2 = true;
            this.dashboard3 = true;
        }
        else if (this.dashboardV2.length > 0){
            this.dashboard1 = true;
            this.dashboard2 = false;
            this.dashboard3 = true;
        }
        else if (this.dashboardV3.length > 0) {
            this.dashboard1 = true;
            this.dashboard2 = true;
            this.dashboard3 = false;
        }
        this.searchList = _.filter(this.urlList, function (obj) { if (obj.menuId == "7") return obj; });
        this.masterList = _.filter(this.urlList, function (obj) { if (obj.menuId == "2") return obj; });
        if (this.masterList.length > 0) {
            this.masterHeader = this.masterList.find(x => x.menuId == "2").moduleName;
        }
        this.gstr1List = _.filter(this.urlList, function (obj) { if (obj.menuId == "3") return obj; });
        if (this.gstr1List.length > 0) {
            this.gstr1Header = this.gstr1List.find(x => x.menuId == "3").moduleName;
        }
        this.gstr2List = _.filter(this.urlList, function (obj) { if (obj.menuId == "4") return obj; });
        if (this.gstr2List.length > 0) {
            this.gstr2Header = this.gstr2List.find(x => x.menuId == "4").moduleName;
        }
        //this.purchaseList = _.filter(this.urlList, function (obj) { if (obj.menuId == "5") return obj; });
        //if (this.purchaseList.length > 0) {
        //    this.purchaseHeader = this.purchaseList.find(x => x.menuId == "5").moduleName;
        //}
        this.drcr = _.filter(this.urlList, function (obj) { if (obj.menuId == "6") return obj; });
        if (this.drcr.length > 0) {
            this.drcrHeader = this.drcr.find(x => x.menuId == "6").moduleName;
        }
        this.feedback = _.filter(this.urlList, function (obj) { if (obj.menuId == "9") return obj; });
        if (this.feedback.length > 0) {
            this.feedbackHeader = this.feedback.find(x => x.menuId == "9").moduleName;
        }
        this.reportList = _.filter(this.urlList, function (obj) { if (obj.menuId == "8") return obj; });
        if (this.reportList.length > 0) {
            this.reportHeader = this.reportList.find(x => x.menuId == "8").moduleName;
        }
        this.account = _.filter(this.urlList, function (obj) { if (obj.menuId == "17") return obj; });
        this.doclist = _.filter(this.urlList, function (obj) { if (obj.menuId == "11" && obj.subMenuId == "25") return obj; });
        this.adminList = _.filter(this.urlList, function (obj) {
            if (obj.menuId == "14" || obj.menuId == "15" || obj.menuId == "16" || obj.menuId == "12" || obj.menuId == "13") {
               
                return obj;
            }
        });
      }
    }).catch(error => {
      this.urlList = [];
    });
  }
  }

  getUserImg()
  { if (!this.authService.authData.isAuth) {
    this.router.navigate(["/login"]);
  }
  else{
      this.httpService.get(APIURLS.BR_MASTER_USRIMG_API).then((data: any) => {
      this.uimgList = data.uImgUrl == null || data.uImgUrl == ""? '':data.uImgUrl;
      this.cmpimg = data.cImg == null || data.cImg == ""? '../assets/dist/img/Companylogo.jpg' : data.cImg;
       this.cmp_name = data.cmp_name;
    }).catch(error => {
      this.uimgList = null;
    });
  }
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }

  getMsgBoardMasterList() {
    if (!this.authService.authData.isAuth) {
      this.router.navigate(["/login"]);
    }
    else{
    this.httpService.get(APIURLS.BR_MASTER_DISPLAYMSGBOARD_API).then((data: any) => {
      if (data.status == 'SUCCESS') {
        this.messageBoardList = data.messageBoardList;
      }
    }).catch(error => {
      this.messageBoardList = [];
    });
  }
  }

  routetoUrl(url: string) {
    let routeUrl: string = '';
    if (url != "#/") {
      if (!/^http[s]?:\/\//.test(url)) {
        routeUrl = 'https://';
      }
      routeUrl = routeUrl + url;
      window.open(routeUrl, '_blank');
      //window.location.href = routeUrl;
    }
    else {
      window.open(routeUrl, '_blank');
    }
  }

  validateUrlAccess(subMenu:string) {
      this.urlAccess = _.filter(this.urlList, function (obj) { if (obj.subMenuId == subMenu) return obj; });//this.appService.urlList.find(s => s.name == "Entity");
      
      if (this.urlAccess.length > 0) {
          
          return true;
      }
      else {
          return false;
      }
  }
  validateDashboardAccess(menu: string) {
      this.urlAccess = _.filter(this.urlList, function (obj) { if (obj.menuId == menu) return obj; });

      if (this.urlAccess.length > 0) {
          return true;
      }
      else {
          return false;
      }
  }
  validateApprovalUrlAccess(menu: string,subMenu: string) {
      this.urlAccess = _.filter(this.urlList, function (obj) { if (obj.menuId == menu && obj.subMenuId == subMenu) return obj; });//this.appService.urlList.find(s => s.name == "Entity");
      if (this.urlAccess.length > 0) {
          return true;
      }
      else {
          return false;
      }
  }

}
