export class APIURLS {
   // public static BR_BASE_URL: string = "http://localhost:50225/api/";
    public static BR_BASE_URL: string = "https://live.bookright.in/brapi/api/";
    //public static BR_BASE_URL: string = "http://35.154.12.85/brapi/api/";
    //public static BR_BASE_URL: string = "http://13.127.131.205/brapi/api/";
    public static BR_AUTH_API: string = "token";
    public static BR_MASTER_ENTITY_API: string = "entity";
    public static BR_INVOICE_SEARCH_API: string = "SearchInvoice";
    public static BR_RINVOICE_SEARCH_API: string = "SearchRInvoice";
    public static BR_INVOICE_APPROVE_API: string = "ApproveInvoice";
    public static BR_INVOICE_API: string = "Invoice";
    public static BR_RINVOICE_API: string = "Purchase";
    public static BR_INVOICE_APPROVEPURCHASE_API: string = "ApprovePurchaseInv";
    public static BR_MASTER_DIVISION_API: string = "division";
    public static BR_FORGOTPASSWORD_API: string = "forgotpassword";
    public static BR_DD_CUSTOMER_API: string = "DDCustomer";
    public static BR_DD_DIVISION_API: string = "DDDivision";
    public static BR_DD_USERDIVISION_API: string = "UserDivision";
    public static BR_DD_USER_API: string = "DDUser";
    public static BR_DD_ENTITY_API: string = "DDEntity";
    public static BR_Master_CUSTOMER_API: string = "Customer";
    public static BR_MASTER_PRODUCT_API: string = "product";
    public static BR_MASTER_SUPPLIER_API: string = "supplier";
    public static BR_MASTER_CUSTOMER_API: string = "customer";
    public static BR_MASTER_STATE_API: string = "state";
    public static BR_MASTER_USER_API: string = "user";
    // public static BR_MASTER_GET_USER_API: string = "user";
    public static BR_DASHBOARD_EXCEL_REPORT = "DashboardExcel";
    public static BR_DASHBOARD_DATA = "Dashboard";
    public static BR_REPORT_API = "Report";
    public static BR_DIVREPORT_API = "DivReport";
    public static BR_MASTER_COMPANY_API = "Company";
    public static BR_MASTER_FORM_API = "Form";
    public static BR_MASTER_ROLE_API = "Role";
    public static BR_MASTER_DEPARTMENT_API = "Department";
    public static BR_MASTER_PROFILE_API = "Profile";
    public static BR_MASTER_PROFILEFORMMAIN_API = "FormMaintenance";
    public static BR_INVOICE_SEARCHDRCR_API: string = "SearchDrCr";
    public static BR_INV_DEBITCREDIT_API: string = "DebitCredit";
    public static BR_MASTER_USRIMG_API: string = "DDUserImg";
    public static BR_MASTER_FILEUPLOAD_API: string = "FileUpload";
    public static BR_MASTER_FILTERDIV_API: string = "FilterDiv";
    public static BR_MASTER_FEEDBACK_API: string = "Feedback";
    public static BR_MASTER_MSGBOARD_API: string = "MessageBoard";
    public static BR_MASTER_DISPLAYMSGBOARD_API: string = "DisplayMessageBoard";
    public static BR_DASHBOARD_FEEDBACK_API: string = "DashboardFeedback";
    public static BR_INV_GS_PRODLIST_API: string = "GSInvProductList";
    public static BR_MASTER_CALENDAR_API: string = "Calendar";
    public static BR_DASHBOARDFILTER_DATA_API: string = "DashboardFilter";
    public static BR_SEARCH_DRCRINVOICE_API: string = "SearchDrCrInvoice";
    public static BR_SEARCH_DRCRPURCHASE_API: string = "SearchDrCrPurchase";
    public static BR_APPROVE_DRCR_API: string = "ApproveDrcr";
    public static BR_FORM_DATA_API: string = "AppMastersStructure";
    public static BR_APP_INV_STRUCTURE_API: string = "AppInvoiceStructure";
    public static BR_RESET_PASSWORD_API: string = "ResetPassword";
    public static BR_MASTER_PRICE_LIST_API: string = "PriceList";
    public static BR_MASTER_INV_FOR_PRICE_LIST_API: string = "PriceListForInv";
    public static BR_SUPPLIER_INVOICE_SEARCH_API: string = "SearchSupplierInvoice";
    public static BR_SUPPLIER_INVOICE_APPROVE_API: string = "ApproveSupplierInv";
    public static BR_SUPPLIER_INVOICE_API: string = "SupplierInvoice";
    public static BR_MASTER_COLLECTION_API: string = "Collection";
    public static BR_MASTER_PAYMENT_API: string = "Payment";
    public static BR_MASTER_EXPENSE_API: string = "Expense";
    public static BR_MASTER_EXPENSE_ENTRY_API: string = "ExpenseEntry";
    public static BR_PAYMENT_ADV_API: string = "PaymentAdvance";
    public static BR_UPLOAD_COMPANY_LOGO_API: string = "UploadLogo";
    public static BR_MASTER_CUST_PRICE_LIST_API: string = "CustPriceList";
    public static BR_MASTER_INV_FOR_CUST_PRICE_LIST_API: string = "CustPriceListForInv";
    public static BR_UOM_CODE_API: string = "UomCode";
    public static BR_MASTER_TAILOR_API: string = "Tailor";
    public static BR_SC_MASTER_CUSTOMER_API: string = "Sc_CustMasters";
    public static BR_SC_MASTER_SALESPERSON_API: string = "Sc_SalesPersonMasters";
    public static BR_MASTER_MEASUREMENT_API: string = "Measurement";
    public static BR_MASTER_SC_CUSTINV_API: string = "Sc_CustInv";
    public static BR_MASTER_SC_SUPINV_API: string = "Sc_SupInv";
    public static BR_MASTER_SC_ORDER_TYPE_API: string = "Sc_OrderType";
    public static BR_MASTER_SC_SEARCH_CUSTINV_API: string = "Sc_SearchCustInv";
    public static BR_MASTER_SC_SEARCH_SUPINV_API: string = "Sc_SearchSupInv";
    public static BR_TAILOR_INVOICE_SAVE_API: string = "Sc_TailorInvoice";
    public static BR_TAILOR_INVOICE_DETAILS_UPDATE_API: string = "Sc_TailorInvoiceDetails";
    public static BR_TAILOR_REPORTS_API: string = "Sc_TailorReport";
    public static BR_SC_PAYMENT_COLLECTION_API: string = "Sc_CustOrderPayment";
    public static BR_SC_INV_PRODUCT_LIST_API: string = "Sc_GetInvProductList";
    public static BR_SC_MASTER_PRODUCT_API: string = "Sc_Product";
    public static BR_INVOICEMAIL_API: string = "InvoiceMail";
    public static BR_SUPINVOICEMAIL_API: string = "SupInvoiceMail";
    public static BR_CUSTINVOICEMAIL_API: string = "Sc_CustInvMail";
    public static BR_TEMPLATE_MASTER_API: string = "Template";

}
