﻿export class AuthData {
    constructor(
        public isAuth: boolean,
        public userId: number,
        public userName: string,
        public firstName: string,
        public lastName: string,
        public country: string,
        public email: string,
        public role: string[],
        public token: string,
        public isapprover: boolean,
        public usr_level: number,
        public roleId: number,
        public status: boolean,
        public expiresIn: number) { }
}