﻿import { APIURLS } from './../shared/api-url';
import { HttpService } from './../shared/http-service';
import { AuthData } from './auth.model';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';
@Injectable()
export class AuthService {
    authData: AuthData = new AuthData(false, 0, '', '', '', '','', [], '', false, 0, 0, true, 0);
    myStorage = window.localStorage;
    constructor(private httpService: HttpService) { }

  login(username, password) {
    let postParams = {
      username: username,
      password: password
    }
    const promise = new Promise((resolve, reject) => {
      this.httpService.postLogin(postParams).then(data => {
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
    return promise;
  }

  logout() {
      localStorage.removeItem('currentUser');
      localStorage.clear();
      this.authData = new AuthData(false, 0, '', '', '', '','', [], '', false, 0, 0, true, 0);
  }

  
  isAuthenticated() {
    if (this.authData.isAuth) {
      return true;
    }
    else {
      return false
    }

  }


}
