import { APIURLS } from './../shared/api-url';
import { ExcelService } from './../shared/excel-service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpService } from '../shared/http-service';
import { AppComponent } from './../app.component';
//import { Component, OnInit } from '@angular/core';
import { ViewChild, Component, Injector, OnInit } from '@angular/core';
import * as alaSQLSpace from 'alasql';
import * as _ from "lodash";
//import morris from 'morris.js';
declare var jQuery: any;

@Component({
  selector: 'app-dashboardv2',
  templateUrl: './dashboardv2.component.html',
  styleUrls: ['./dashboardv2.component.css']
})
export class DashboardV2Component implements OnInit {
    
   // @ViewChild('#pieChart') donut: ElementRef;
  public tableWidget: any;
  public latesttableWidget: any;
  dashboardData: any;
  feedbackList: any[];
  reportSource: number;
  monthSelected: number = 0;
  SelFilter: number = 0;
  startDate: Date = new Date();
  endDate: Date = new Date();
  isChecked: boolean = false;
  tax1: any[];
  errMsg: string = "";
  isLoading: boolean = false;
  errMsgPop: string = "";
  isLoadingPop: boolean = false;
  errMsgStrt: string = "";
  errMsgEnd: string = "";
  isValidate: boolean = true;
  inv_nbrText: string = "";
  oTable: any;
  constructor(private appService: AppComponent, private httpService: HttpService, private excelService: ExcelService, private injector: Injector, private route: ActivatedRoute, private router: Router) {  }

  private initDatatable(): void {
    //let exampleId: any = jQuery('#complaintTable');
    //this.tableWidget = exampleId.DataTable();
    let exampleLatId: any = jQuery('#tbl_latest_orders');
    this.latesttableWidget = exampleLatId.DataTable();
    this.latesttableWidget.order([0, 'desc']).draw();
  }

  private reInitDatatable(): void {
    //if (this.tableWidget) {
    //  this.tableWidget.destroy()
    //  this.tableWidget = null
    //}
    if (this.latesttableWidget) {
        this.latesttableWidget.destroy()
        this.latesttableWidget = null
    }
    setTimeout(() => this.initDatatable(), 0)
  }

  ngAfterViewInit() {
     
      //debugger;
     // var myCanvas = jQuery('#pieChart');
      //debugger;
      //var ele = jQuery('#pieChart')[0];
      //debugger;
     // var ctx = myCanvas[0].getContext('2d');
     
  }

  ngOnInit() {
      this.getDashBoardData();
      //this.initDatatable();
  }
   
  getDashBoardData() {
    this.errMsg = "";
    this.isLoading = true;
    this.httpService.getById(APIURLS.BR_DASHBOARDFILTER_DATA_API, 4).then((data: any) => {
      this.isLoading = false;
      if (data.status == 'SUCCESS') {
          this.dashboardData = data;
          //this.tax1 = data.currentMonthOutward;
          this.reInitDatatable();
      }
      
    }).catch(error => {
      this.isLoading = false;
        this.dashboardData = [];
    });
  }

  navigateToView(inv: any) {
      var path = '/sc_invoices/' + inv.invid;
      localStorage.setItem('comefrom', "/dashboardv2");
      this.router.navigateByUrl(path);
  }

  navigatetoreports(id) {
      var path = '/sc_custreport/' + id;
      localStorage.setItem('comefrom', "/dashboardv2");
      console.log("path :",path)
      this.router.navigateByUrl(path);
  }

  calGrandTotal(inv: any) {
    var inv_total_value = 0;
    var invDetList = inv.sc_dashbdcustInvDetails;
    var invDetFileteredList = _.filter(invDetList, function (o: any) { return o.invd_line_disc_percent != 0; });
    if (invDetFileteredList.length > 0) {
      if (inv.inv_ordertype == 3) {
       
        
          var totalInvSum1 = _.sumBy(inv.sc_dashbdcustInvDetails, function (obj) {
            if (obj.invd_line_disc_percent != 0) {
            return obj["invd_qty"] * obj["invd_discount_amt"];
            }
             else {
               return 0.0;
             }
          });
          inv_total_value = totalInvSum1;
          //var discountPercentAmt1 = totalInvSum1 * inv.discountPercent / 100;
          //inv_total_value = inv_total_value + discountPercentAmt1;
       
      }
      else {
        
        
          var totalInvSum1 = _.sumBy(inv.sc_dashbdcustInvDetails, function (obj) {
            if (obj.invd_type != 3 && obj.invd_type != 4 && obj.invd_line_disc_percent != 0) {
              return obj["invd_qty"] * obj["invd_discount_amt"];
            }
            else {
              return 0.0;
            }
          });
          inv_total_value = totalInvSum1;
          //var discountPercentAmt1 = totalInvSum1 * inv.discountPercent / 100;
          //inv_total_value = inv_total_value + discountPercentAmt1;
       
      }
    }
    else {
      if (inv.inv_ordertype == 3) {
        if (inv.discountAmount != 0) {
          inv_total_value = inv.discountAmount;
        }
        if (inv.discountPercent != 0) {
          var totalInvSum1 = _.sumBy(inv.sc_dashbdcustInvDetails, function (obj) {
            //if (obj.invd_type != 3 && obj.invd_type != 4) {
            return obj["invd_qty"] * obj["invd_price_ntax"];
            //}
            // else {
            //   return 0.0;
            // }
          });
          var discountPercentAmt1 = totalInvSum1 * inv.discountPercent / 100;
          inv_total_value = inv_total_value + discountPercentAmt1;
        }
      }
      else {
        if (inv.discountAmount != 0) {
          inv_total_value = inv.discountAmount;
        }
        if (inv.discountPercent != 0) {
          var totalInvSum1 = _.sumBy(inv.sc_dashbdcustInvDetails, function (obj) {
            if (obj.invd_type != 3 && obj.invd_type != 4) {
              return obj["invd_qty"] * obj["invd_price_ntax"];
            }
            else {
              return 0.0;
            }
          });
          var discountPercentAmt1 = totalInvSum1 * inv.discountPercent / 100;
          inv_total_value = inv_total_value + discountPercentAmt1;
        }
      }
    }
    return _.round((this.calTotal(inv) - inv_total_value + inv.tailorTotalAmt),2);
      //var inv_total_value = 0;
      //if (inv_total_value != 0) {
      //    inv_total_value = inv_total_value - inv.discountAmount;
      //}
      //if (inv.discountPercent != 0) {
      //    var totalInvSum1 = _.sumBy(inv.sc_dashbdcustInvDetails, function (obj) {
      //        if (obj.invd_type != 3 && obj.invd_type != 4) {
      //            return obj["invd_qty"] * obj["invd_price"];
      //        }
      //        else {
      //            return 0.0;
      //        }
      //    });
      //    var discountPercentAmt1 = totalInvSum1 * inv.discountPercent / 100;
      //    inv_total_value = inv_total_value - discountPercentAmt1;
      //}
      //return inv_total_value;
  }

  calTotal(inv) {
    return _.sumBy(inv.sc_dashbdcustInvDetails, function (obj) { return obj["invd_qty"] * obj["invd_price_ntax"]; });
  }

  }
