﻿import { ExcelService } from './shared/excel-service';
import { HttpService } from './shared/http-service';
import { AuthGuard } from './auth/auth-guard.service';
import { AppRoutingModule } from './app-routing.module';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { AuthService } from './auth/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SearchPipe } from './shared/search-pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';


import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';

import { AddProductComponent } from './masters/product/add-product/add-product.component';

import { InitPageComponent } from './initiallogin/initpage.component';

import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
import { DashboardV2Component } from './dashboardv2/dashboardv2.component';
import { DashboardV3Component } from './dashboardv3/dashboardv3.component';
import { CollectionComponent } from './gstr1/collection/collection.component';
import { PaymentComponent } from './gstr2/payment/payment.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SearchPipe,
    ForgotpasswordComponent,
    AddProductComponent,
    InitPageComponent,
    ResetPasswordComponent,
    DashboardV2Component,
    DashboardV3Component,
    CollectionComponent,
    PaymentComponent
  ],
  imports: [
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpModule,
      AppRoutingModule,
      
  
  ],
  providers: [AuthService, AuthGuard, HttpService, ExcelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
